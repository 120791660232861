import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Form, FormLabel, Row } from "react-bootstrap";

import arrow from "../../assets/images/down.png";
import { useSelector } from "react-redux";
import moment from "moment";
const AddSlotModal = (props) => {
  let {
    onHide,
    formData,
    modalHeader,
    handleOnChange,
    submitHandler,
    checked,
    validation,
    handleOnChangecheckbox,
    slotSwitch,
  } = props;

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton 
        className="btn-close-white "
        >
          <Modal.Title
            id="contained-modal-title-vcenter "
            // className="text-white"
          >
            {modalHeader}
          
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="booking_slot">
            <div className="row">
              <Form
                noValidate
                validated={validation}
                onSubmit={(e) => submitHandler(e, modalHeader)}
              >
                <Row>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <FormLabel style={{ color: "white" }}>Start Time</FormLabel>
                    <Form.Control
                      name="fromTime"
                      style={{ color: "white" }}
                      type="time"
                      className="form-control input-control  "
                      value={formData.fromTime}
                      onChange={handleOnChange}
                      required
                    />

                    <Form.Control.Feedback type="invalid">
                      Start time Required
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="6" controlId="validationCustom02">
                    <FormLabel style={{ color: "white" }}>End Time</FormLabel>
                    <Form.Control
                      name="toTime"
                      style={{ color: "white" }}
                      type="time"
                      className="form-control input-control  "
                      value={formData.toTime}
                      onChange={handleOnChange}
                      required
                    />

                    <Form.Control.Feedback type="invalid">
                      End time Required
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="mt-2">
                  <Form.Group
                    as={Col}
                    md={`${slotSwitch ? "12" : "6"}`}
                    controlId="validationCustom03"
                  >
                    <FormLabel style={{ color: "white" }}>Age Group</FormLabel>
                    <Form.Select
                      name="ageGroup"
                      aria-label="Default select example"
                      className="form-control form-select input-control"
                      value={formData.ageGroup}
                      onChange={handleOnChange}
                      required
                    >
                      <option value="">Age Group</option>
                      <option value="1">Under Eleven</option>
                      <option value="2">Above Eleven</option>
                    </Form.Select>
                    <img className="set_arrowsss" src={arrow} alt="arrow" />

                    <Form.Control.Feedback type="invalid">
                      Please Select any Option
                    </Form.Control.Feedback>
                  </Form.Group>

                  {!slotSwitch && (
                    <Form.Group
                      as={Col}
                      md={"6"}
                      controlId="validationCustom03"
                    >
                      <FormLabel style={{ color: "white" }}>
                        Slot Date
                      </FormLabel>
                      <Form.Control
                        name="slotDate"
                        type="date"
                        aria-label="Default select example"
                        className="form-control input-control "
                        value={moment(formData.slotDate).format("YYYY-MM-DD")}
                        onChange={handleOnChange}
                        required
                      ></Form.Control>

                      <Form.Control.Feedback type="invalid">
                        Date is Required
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                </Row>
                {slotSwitch && (
                  <Row style={{ margin: " 10px 0px" }}>
                    <Form.Group
                      as={Col}
                      md="12"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      className="form-control input-control "
                      controlId="validationCustom02"
                    >
                      <Form.Check
                        aria-label="option 0"
                        label="Mon"
                        type="checkbox"
                        id="checkbox"
                        name="Mon"
                        checked={checked.Mon}
                        onChange={(e) =>
                          handleOnChangecheckbox(e, !checked.Mon)
                        }
                      />
                      <Form.Check
                        aria-label="option 1"
                        label="Tues"
                        type="checkbox"
                        id="checkbox"
                        name="Tues"
                        checked={checked.Tues}
                        onChange={(e) =>
                          handleOnChangecheckbox(e, !checked.Tues)
                        }
                      />
                      <Form.Check
                        aria-label="option 2"
                        label="Wed"
                        type="checkbox"
                        id="checkbox"
                        name="Wed"
                        checked={checked.Wed}
                        onChange={(e) =>
                          handleOnChangecheckbox(e, !checked.Wed)
                        }
                      />
                      <Form.Check
                        aria-label="option 3"
                        label="Thur"
                        type="checkbox"
                        id="checkbox"
                        name="Thur"
                        checked={checked.Thur}
                        onChange={(e) =>
                          handleOnChangecheckbox(e, !checked.Thur)
                        }
                      />
                      <Form.Check
                        aria-label="option 4"
                        label="Fri"
                        type="checkbox"
                        id="checkbox"
                        name="Fri"
                        checked={checked.Fri}
                        onChange={(e) =>
                          handleOnChangecheckbox(e, !checked.Fri)
                        }
                      />
                      <Form.Check
                        aria-label="option 5"
                        label="Sat"
                        type="checkbox"
                        id="checkbox"
                        name="Sat"
                        checked={checked.Sat}
                        onChange={(e) =>
                          handleOnChangecheckbox(e, !checked.Sat)
                        }
                      />
                      <Form.Check
                        aria-label="option 6"
                        label="Sun"
                        type="checkbox"
                        id="checkbox"
                        name="Sun"
                        checked={checked.Sun}
                        onChange={(e) =>
                          handleOnChangecheckbox(e, !checked.Sun)
                        }
                      />
                    </Form.Group>
                  </Row>
                )}

                <Row>
                  <Col as={Col} md="12">
                    <div className="d-flex justify-content-center">
                      <Button
                        type="submit"
                        className="w-25 my-4 autoButton autoButtonAnimation "
                      // onClick={() => submitHandler()}
                      >
                        Submit
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddSlotModal;
