import React, { useEffect, useState } from "react";
import "./index.css";
import user from "../../assets/images/user.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmBookingLessonAction, GetAllBookingDetailsByIdAction } from "../../redux/actions/GetAllBookingAction";

import { Modal, Button } from "react-bootstrap";

const BookingDetailsModal = (props) => {
  let { onHide, modalHeader } = props;
  const token = localStorage?.userData;
  let parsing = token ? JSON.parse(localStorage?.userData) : null;
  let usertype = parsing?.userType || null;

  const { getAllBookingDetailsById } = useSelector(
    (state) => state.getAllBookingDetailsById
  );
  const { ConfirmBookingLessonReducer } = useSelector((state) => state.confirmBookingLesson)
  const dispatch = useDispatch();
  // const confirmClick=ConfirmBookingLessonReducer?.confirmed
  const userData = getAllBookingDetailsById?.data;

  const { hash } = useLocation();
  let id = parseInt(hash.split("#")[1]);

  useEffect(() => {
    if (id) {
      let obj = {
        bookingId: +id,
      };
      dispatch(GetAllBookingDetailsByIdAction(obj));
    }
  }, [id]);

  const ConfirmHandler = (confirmId) => {
    let obj = {
      bookingId: id,
      lessonId: confirmId,
      isConfirmed: true,
    };
    dispatch(ConfirmBookingLessonAction(obj))
  }

  return (
    <>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className=" bookingcard">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  margin: "20px",
                }}
              >
                <div>
                  {usertype === 2 ? (
                    <img
                      height="200px"
                      width="200px"
                      style={{
                        border: "4px solid rgba(227, 130, 38, 1) ",
                        borderRadius: "50%",
                      }}
                      src={
                        userData?.studentImage ? userData?.studentImage : user
                      }
                      alt={user}
                    />
                  ) : (
                    <img
                      height="200px"
                      width="200px"
                      style={{
                        border: "4px solid rgba(227, 130, 38, 1)",
                        borderRadius: "50%",
                      }}
                      src={userData?.coachImage ? userData?.coachImage : user}
                      alt={user}
                    />
                  )}
                </div>

                <div className="card-title" style={{ marginTop: "20px" }}>
                  {usertype === 2 ? (
                    <h3>{userData?.studentName}</h3>
                  ) : (
                    <h3>{userData?.coachName}</h3>
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    borderBottom: "2px solid rgba(227, 130, 38, 0.2)",
                    width: "100%",
                  }}
                ></div>
              </div>

              <div
                className="card-body"
                style={{ display: "flex", justifyContent: "space-evenly" }}
              >
                {/* 1st column */}
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "20px",
                    }}
                  >
                    <div className="detail_icon">
                      <i
                        class="fa fa-address-book fs-2 iconStyle"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div
                      style={{
                        paddingLeft: "20px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <h5 className="card-text">contact</h5>
                      <h5 className="card-text-value ">{userData?.phone}</h5>
                    </div>
                  </div>

                </div>
                {/* 2nd column */}
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "20px",
                    }}
                  >
                    <div className="detail_icon">
                      <i
                        class="fa fa-credit-card-alt fs-3 iconStyle"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div style={{ paddingLeft: "20px" }}>
                      <h5 className="card-text">payment</h5>
                      <h6 className="card-text-value ">
                        {userData?.paymentStatus}
                      </h6>
                    </div>
                  </div>

                </div>
                {/* 3rd column */}
                <div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "20px",
                    }}
                  >
                    <div className="detail_icon">
                      <i
                        class="fa fa-map-marker fs-1 iconStyle"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div style={{ paddingLeft: "20px" }}>
                      <h5> location</h5>
                      <h6 className="card-text-value ">{userData?.location}</h6>
                    </div>
                  </div>
                </div>
                {/* 4th column */}
                <div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "20px",
                    }}
                  >
                    <div className="detail_icon">
                      <i
                        class="fa fa-inr fs-2 iconStyle"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div style={{ paddingLeft: "20px" }}>
                      <h5>Price</h5>
                      <h6 className="card-text-value ">{userData?.price}</h6>
                    </div>
                  </div>
                </div>
                {/* 5th column */}
                <div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "20px",
                    }}
                  >
                    <div className="detail_icon">
                      <i
                        class="fa fa-envelope fs-2 iconStyle"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div style={{ paddingLeft: "20px" }}>
                      <h5 className="card-text">Email</h5>
                      <h6 className="card-text-value ">{userData?.email}</h6>
                    </div>
                  </div>
                </div>
              </div>

              {/* slot data */}

              {getAllBookingDetailsById?.data?.slotsList?.map((item) => {
                let dateupdate = new Date(item.date).toLocaleDateString();
                return (
                  <div>

                    <div className="name_section col-sm-md-lg-12">

                      <div
                        style={{
                          borderLeft: "1px solid #575757",
                          height: "70px",
                        }}
                      ></div>

                      {/* <div className="coach_price">
    <div className="coach_price_label">
      <h3>Name</h3>
    </div>

    <div className="coach_fullname">
      {usertype === 2 ? (
        <h4 className="text-white">{user?.studentName}</h4>
      ) : (
        <h4 className="text-white">{user?.coachName}</h4>
      )}
    </div>
  </div> */}
                      <div
                        style={{
                          borderLeft: "1px solid #575757",
                          height: "70px",
                        }}
                      ></div>
                      <div className="coach_price">
                        <div className="coach_price_label">
                          <h3>Booking Date</h3>
                          <h4>{dateupdate}</h4>
                        </div>
                        <div className="coach_full_price">

                        </div>
                      </div>
                      <div
                        style={{
                          borderLeft: "1px solid #575757",
                          height: "70px",
                        }}
                      ></div>

                      <div className="coach_price">
                        <div className="coach_price_label">
                          <h3> Slot Time</h3>
                          <h4>{item.time}</h4>
                        </div>
                        <div className="coach_full_price">

                        </div>
                      </div>

                      <div
                        style={{
                          borderLeft: "1px solid #575757",
                          height: "70px",
                        }}
                      ></div>

                      <div className="coach_price">
                        <div className="coach_price_label">
                          <h3>day</h3>
                          <h4>{item.weekDays}</h4>
                        </div>
                        <div className="coach_full_price">

                        </div>
                      </div>
                      <div
                        style={{
                          borderLeft: "1px solid #575757",
                          height: "70px",
                        }}
                      ></div>

                      <div className="coach_price col-md-sm-xs-3">
                        <div className="coach_price_label">
                          <h3> Confirm </h3>
                        </div>
                        <div className="coach_full_price">
                          <i
                            className="fa fa-check text-white fs-5 cursor_pointer"
                            aria-hidden="true"
                            onClick={() => ConfirmHandler(item.lessonId)}
                          ></i>
                        </div>
                      </div>
                    </div>


                  </div>
                )
              })}


            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BookingDetailsModal;
