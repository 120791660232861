import React, { useEffect } from "react";
import { GetAllSportsAction } from "../redux/actions/GetAllSports";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { GetCoachByIdAction } from "../redux/actions/coach";
import { useNavigate } from "react-router-dom";
import "./Games.css";
import ReactGA from "react-ga";

let count = 0;
const Games = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0)

  const { getAllSports } = useSelector((state) => state.getAllSportsResponse);
  const data = getAllSports?.data;
  const response = getAllSports?.statusCode;

  const [sportsList, setSportsList] = useState([]);

  const [list, setList] = useState([]);

  const [Page, setPage] = useState({
    page: 1,
    pageSize: 99,
  });

  useEffect(() => {
    if (response == 200) {
      setSportsList(data);
    }
  }, [getAllSports]);

  useEffect(() => {
    dispatch(GetAllSportsAction(Page));
  }, []);

  // useEffect(() => {
  //   dispatch(GetAllSportsAction(Page));
  // }, [Page]);

  const coachHandler = (data) => {
    localStorage.setItem("sportsId", data.sportId);
    let obj = {
      sportId: data.sportId,
      page: 1,
      pageSize: 10,
    };
    dispatch(GetCoachByIdAction(obj));
    navigate("/coachSearch");
    ReactGA.event({
      category: "Sports",
      action: `SportsName - ${data.sportName}`,
    });
  };

  const rightSlideHandler = () => {

    if (currentIndex < count)
      setCurrentIndex(currentIndex + 1)

  };

  const leftSlideHandler = () => {
    if (currentIndex > 0)
      setCurrentIndex(currentIndex - 1)

  };

  useEffect(() => {
    if (sportsList.length > 0) {
      count = Math.ceil((sportsList.length) / 2);
      const list = sportsList.slice(currentIndex, currentIndex + 3);
      setList(list);
    }
  }, [sportsList]);

  useEffect(() => {
    const list = sportsList.slice(currentIndex, currentIndex + 3);
    setList(list);
  }, [currentIndex]);

  console.log("current index", list)
  return (
    <>
      <div className="games">
        {/* <p className="games_header">What you like to learn or teach</p> */}
        {/* <p className="games_desc">Sports Offered</p> */}
        {/* <div className="container">
          <div className="row">
            <div className="col-md-12 mx-auto">

              <div
                id="myCarousel"
                className="carousel slide"
                data-ride="carousel"
                data-interval="0"
              >
                
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="row">
                      {list?.length &&
                        list?.map((user) => (
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                            

                            <div className="cards">
                              <img
                                src={user?.sportImage}
                                className="img-fluid"
                                alt="img"
                                onClick={() => coachHandler(user)}
                              />
                              <div className="thumb-content">
                                <h4>{user?.sportName}</h4>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>

              <a
                className="carousel-control-prev"
                href="#myCarousel"
                data-slide="prev"
              >
                <i
                  className="fa fa-angle-left"
                  onClick={() => leftSlideHandler()}
                ></i>
              </a>
              <a
                className="carousel-control-next"
                href="#myCarousel"
                data-slide="next"
              >
                <i
                  className="fa fa-angle-right"
                  onClick={() => rightSlideHandler()}
                ></i>
              </a>


            </div>
          </div>
        </div> */}
        <div className="  container">
          <div className="row justify-content-center">

            <div className="col-md-8   ">
              <h3 className="sports-list">  Available Sports</h3>
              <div className="d-flex justify-content-between">
                <div>
                  <ul className="text-white liststyle" >
                    <li style={{ color: "orange", marginBottom: "20px" }}>Women's</li>
                    <li><i class="fa fa-check" aria-hidden="true"></i> &nbsp; Softball</li>
                    <li> <i class="fa fa-check" aria-hidden="true"></i> &nbsp; Basketball</li>
                    <li> <i class="fa fa-check" aria-hidden="true"></i> &nbsp; Cross Country</li>
                    <li> <i class="fa fa-check" aria-hidden="true"></i> &nbsp; Swimming and Diving</li>
                    <li><i class="fa fa-check" aria-hidden="true"></i> &nbsp; Gymnastics</li>
                    <li><i class="fa fa-check" aria-hidden="true"></i> &nbsp; Track and Field</li>
                    <li><i class="fa fa-check" aria-hidden="true"></i> &nbsp; Tennis</li>
                    <li><i class="fa fa-check" aria-hidden="true"></i> &nbsp; Soccer</li>
                    <li><i class="fa fa-check" aria-hidden="true"></i> &nbsp; Volleyball</li>


                  </ul>
                </div>
                <div>
                  <ul className="text-white liststyle">
                    <li style={{ color: "orange", marginBottom: "20px" }}>Men's</li>
                    <li> <i class="fa fa-check" aria-hidden="true"></i> &nbsp; Baseball</li>
                    <li> <i class="fa fa-check" aria-hidden="true"></i> &nbsp; Basketball</li>
                    <li> <i class="fa fa-check" aria-hidden="true"></i> &nbsp; Cross Country</li>
                    <li> <i class="fa fa-check" aria-hidden="true"></i> &nbsp; Football</li>
                    <li> <i class="fa fa-check" aria-hidden="true"></i> &nbsp; Gymnastics</li>
                    <li> <i class="fa fa-check" aria-hidden="true"></i> &nbsp; Track and Field</li>
                    <li> <i class="fa fa-check" aria-hidden="true"></i> &nbsp; Tennis</li>
                    <li> <i class="fa fa-check" aria-hidden="true"></i> &nbsp; Wrestling</li>


                  </ul>
                </div>
              </div>

              <h3 className="sports-list "> Coming Next</h3>
              {/* <div className="d-flex justify-content-center"> */}
              <div className="d-flex justify-content-between">

                <div>
                  <ul className="text-white liststyle">
                    {/* <li>Wheelchair Basketball:</li> */}
                    <li style={{ color: "orange", marginBottom: "20px" }}>Women's</li>
                    <li><i class="fa fa-check" aria-hidden="true"></i> &nbsp; Wheelchair Basketball</li>
                    <li><i class="fa fa-check" aria-hidden="true"></i> &nbsp; Water polo</li>
                    <li><i class="fa fa-check" aria-hidden="true"></i> &nbsp; Hockey</li>
                    <li><i class="fa fa-check" aria-hidden="true"></i> &nbsp; Lacrosse</li>
                    <li><i class="fa fa-check" aria-hidden="true"></i> &nbsp; Rowing</li>
                    <li><i class="fa fa-check" aria-hidden="true"></i> &nbsp; Field Hockey</li>
                    <li><i class="fa fa-check" aria-hidden="true"></i> &nbsp; Fencing</li>
                    <li><i class="fa fa-check" aria-hidden="true"></i> &nbsp; Dance</li>
                    <li><i class="fa fa-check" aria-hidden="true"></i> &nbsp; Cheerleading</li>
                    <li><i class="fa fa-check" aria-hidden="true"></i> &nbsp; Golf</li>


                  </ul>
                </div>
                <div>
                  <ul className="text-white liststyle">
                    {/* <li>Wheelchair Basketball:</li> */}
                    <li style={{ color: "orange", marginBottom: "20px" }}>Men's</li>
                    <li><i class="fa fa-check" aria-hidden="true"></i> &nbsp; Wheelchair Basketball</li>
                    <li><i class="fa fa-check" aria-hidden="true"></i> &nbsp; Water polo</li>
                    <li><i class="fa fa-check" aria-hidden="true"></i> &nbsp; Hockey</li>
                    <li><i class="fa fa-check" aria-hidden="true"></i> &nbsp; Lacrosse</li>
                    <li><i class="fa fa-check" aria-hidden="true"></i> &nbsp; Rowing</li>
                    <li><i class="fa fa-check" aria-hidden="true"></i> &nbsp; Soccer</li>
                    <li><i class="fa fa-check" aria-hidden="true"></i> &nbsp; Volleyball</li>
                    <li><i class="fa fa-check" aria-hidden="true"></i> &nbsp; Fencing</li>
                    <li><i class="fa fa-check" aria-hidden="true"></i> &nbsp; Dance</li>
                    <li><i class="fa fa-check" aria-hidden="true"></i> &nbsp; Golf</li>



                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default Games;
