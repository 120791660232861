import React, { useEffect } from "react";
// import { ReviewAction } from "../../redux/actions/ReviewAction";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { GetCoachByIdAction } from "../redux/actions/coach";
import { useNavigate } from "react-router-dom";
import "./Review.css";
import ReactGA from "react-ga";
import { PublicReviewAction } from "../redux/actions/ReviewAction";

let count = 0;
const Reviews = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0)

  const { publicreviewReducer } = useSelector((state) => state.publicReviewReducer);
  const data = publicreviewReducer?.data;
  const response = publicreviewReducer?.statusCode;

  const [sportsList, setSportsList] = useState([]);

  const [list, setList] = useState([]);
  console.log("lisssssssttttt", list)
  const [Page, setPage] = useState({
    page: 1,
    pageSize: 10,
  });

  useEffect(() => {
    if (response == 200) {
      setSportsList(data);
    }
  }, [publicreviewReducer]);

  useEffect(() => {
    dispatch(PublicReviewAction(Page));
  }, []);



  const rightSlideHandler = () => {

    if (currentIndex < count)
      setCurrentIndex(currentIndex + 1)

  };

  const leftSlideHandler = () => {
    if (currentIndex > 0)
      setCurrentIndex(currentIndex - 1)

  };

  useEffect(() => {
    if (sportsList.length > 0) {
      count = Math.ceil((sportsList.length) / 2);
      const list = sportsList.slice(currentIndex, currentIndex + 3);
      setList(list);
    }
  }, [sportsList]);

  useEffect(() => {
    const list = sportsList.slice(currentIndex, currentIndex + 3);
    setList(list);
  }, [currentIndex]);

  console.log("current index", list)
  return (
    <>
      <div className="reviews">
        <div> <h2>
          Reviews
        </h2>
        </div>
        <div className="col-md-12 " >


          <div className="container">
            <div className="row">
              <div className="col-md-12 mx-auto">

                <div
                  id="myCarousel"
                  className="carousel slide"
                  data-ride="carousel"
                  data-interval="0"
                >

                  {/* <ol className="carousel-indicators">
                    <li
                      data-target="#myCarousel"
                      data-slide-to="0"
                      className="active"
                    ></li>
                    <li data-target="#myCarousel" data-slide-to="1"></li>
                    <li data-target="#myCarousel" data-slide-to="2"></li>
                  </ol> */}

                  <div className="carousel-inner">
                    <div className="carousel-item active">


                      <div
                        className="card col-12 row"
                        style={{
                          background: "#131313",
                          display: "flex",
                          justifyContent: "space-around",
                          flexDirection: "row",
                        }}
                      >
                        {list.length > 0 &&
                          list.map((review) => {
                            console.log("review", review)
                            return (
                              <div
                                className="card col-4"
                                style={{
                                  background: "black",
                                  width: "32%",
                                  // height: "",
                                  display: "flex",
                                  alignItems: "center",
                                  marginTop: "20px",
                                  borderRadius: "10%",
                                  border: " 1px solid white"
                                }}
                              >
                                <img
                                  className="card-img-top"
                                  src={review?.studentImage}
                                  alt="Card image cap"
                                  style={{
                                    height: "100px",
                                    width: "100px",
                                    borderRadius: "50%",
                                    borderInlineStyle: " solid",
                                    borderInlineColor: "rgba(227, 130, 38, 1)",
                                    marginTop: "20px",
                                  }}
                                />
                                <div
                                  className="card-body"
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >
                                  <h3 className="card-title h3">{review?.studentName}</h3>
                                  <h4>{review?.review}</h4>
                                  <h3 className="d-flex  h3">
                                    {Array.from(Array(review?.rating).keys()).map(
                                      () => (
                                        <div className="pr-2">
                                          <i class="fa fa-star" aria-hidden="true"></i>
                                        </div>
                                      )
                                    )}
                                  </h3>
                                </div>
                              </div>
                            );
                          })}

                      </div>
                    </div>
                  </div>
                </div>

                <a
                  className="carousel-control-prev"
                  href="#myCarousel"
                  data-slide="prev"
                >
                  <i
                    className="fa fa-angle-left"
                    onClick={() => leftSlideHandler()}
                  ></i>
                </a>
                <a
                  className="carousel-control-next"
                  href="#myCarousel"
                  data-slide="next"
                >
                  <i
                    className="fa fa-angle-right"
                    onClick={() => rightSlideHandler()}
                  ></i>
                </a>


              </div>
            </div>
          </div>








        </div>


      </div>
    </>
  );
};

export default Reviews;
