import React, { useEffect, useState } from "react";
import "./CoachSearch.css";
import { Col, Form } from "react-bootstrap";

import team from "../../assets/images/team.png";

import { useLocation, useNavigate } from "react-router-dom";
import { userType } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import {
  GetCoachByIdAction,
  getCoachByVenueIdAction,
  getCoachesByUserRadiusAction,
} from "../../redux/actions/coach";

function valuetext(value) {
  return `${value}°C`;
}

const CoachSearch = () => {
  localStorage.removeItem("venue");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searched = useLocation();

  const venueId = new URLSearchParams(searched.search).get("venueId");
  const sportId = new URLSearchParams(searched.search).get("sportId");
  const radius = new URLSearchParams(searched.search).get("radius");
  const childId = new URLSearchParams(searched.search).get("childId");
  console.log("url---->", venueId, sportId, radius);
  const { getCoachByRadius, getCoachByVenueId, loading } = useSelector(
    (state) => state.getAllCoachResponse
  );
  // const childId = 41;
  const defautFormData = {
    address: "",
    sportId: "",
    minPrice: "",
    maxPrice: "",
    page: 1,
    pageSize: 10,
    search: "",
  };
  localStorage.setItem("childId", childId)
  const [formData, setFormData] = useState(defautFormData);
  const [validated, setValidated] = useState(false);
  const [list, setList] = useState([]);
  const [msg, setMsg] = useState([]);
  const [show, setShow] = useState(false);
  const [value, setValue] = useState([20, 200]);

  const handleChange = (event, newValue) => {
    setFormData({ ...formData, minPrice: newValue[0], maxPrice: newValue[1] });
    setValue(newValue);
  };

  const handleFormData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (getCoachByRadius?.statusCode === 200) {
      setList(getCoachByRadius?.data);
      setMsg(getCoachByVenueId?.returnMessage[0] || []);
    }
    if (getCoachByVenueId?.statusCode === 200) {
      setList(getCoachByVenueId?.data?.coachList);
      setMsg(getCoachByVenueId?.returnMessage[0] || []);
    }
  }, [getCoachByRadius, getCoachByVenueId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const CoachProfileHandler = (id) => {
    let obj = {};
    if (venueId !== null) {
      obj = {
        selectVenue: [{
          name: getCoachByVenueId?.data?.name,
          image: getCoachByVenueId?.data?.image,
          venueId: getCoachByVenueId?.data?.venueId,
          lat: getCoachByVenueId?.data?.lat,
          long: getCoachByVenueId?.data?.long,
        }
        ],

        // .find(
        //   (item, i) => item.coachId === id
        // ).venueList,
        radiusVenue: [],
      };
    }
    if (radius !== null) {
      obj = {
        selectVenue: [],
        radiusVenue: getCoachByRadius?.data.find(
          (item, i) => item.coachId === id
        ).venueList,
      };
    }
    // const data = getCoachByVenueId?.data
    // console.log("select venue", data)

    localStorage.setItem("venue", JSON.stringify(obj));

    localStorage.setItem("coachId", id);

    navigate(`/coachProfileDetail/${id}`);
  };

  const closehandler = () => {
    setShow(false);
  };

  const filterHandler = () => {
    setShow(true);
  };

  useEffect(() => {
    if (venueId !== null && sportId !== null) {
      let obj = {
        venueId: venueId,
        sportId: sportId,

      };
      dispatch(getCoachByVenueIdAction(userType() == 4 ? { ...obj, childId: childId } : obj));
    }

    if (radius !== null && sportId !== null) {
      let obj = {
        radius: radius,
        sportId: sportId,

      };

      //  {...obj ,  childId: userType() == 4 ? (childId) : ''   } 
      dispatch(getCoachesByUserRadiusAction(userType() == 4 ? { ...obj, childId: childId } : obj));
    }

  }, []);




  const filterSerachhandler = (e) => {
    e.preventDefault();
    console.log("data", formData);
    dispatch(GetCoachByIdAction(formData));
    setShow(false);
  };

  return (
    <>
      <div id="search">
        <div className="search_desc">
          <h1>Search For Coaches</h1>
        </div>
      </div>
      <Form noValidate validated={validated}>
        <div className="container-fluid search_container">
          <div className="row ">
            <div className=" col-md-12 col-lg-12 mt-5 main_container_banner ">
              {/* <div style={{ position: "relative" }} className="mt-5 ">
                <input
                  type="text"
                  className="form-control search_inp mt-3"
                  placeholder="Search for lessons"
                  name="search"
                  value={formData.search}
                  onChange={handleFormData}
                />
                <img
                  onClick={filterSerachhandler}
                  src={search}
                  className="search_icon"
                  alt="search"
                />
                <img src={line} className="line_icon" alt="line" />
                <img
                  src={filter}
                  className="filter_icon"
                  alt="filter"
                  onClick={filterHandler}
                />
              </div> */}
              <div className="  main_container_banner">
                <div className="search_card">
                  {list?.length > 0 ? (
                    list?.map((user) => (
                      <div className=" cards_box mb-5">
                        <div className="row">
                          <div
                            className="col-md-5  "
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <img
                                src={
                                  user?.profileImage ? user?.profileImage : team
                                }
                                height="120px"
                                width="120px"
                                style={{
                                  borderRadius: "50%",
                                  borderInlineStyle: " solid",
                                  borderInlineColor: "rgba(227, 130, 38, 1)",
                                  // marginTop: "20px",
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-7 p-4 sidePhoto_text">
                            <div
                              className="text_side"

                            // onClick={() => profileHandler(user)}
                            >
                              <h2>{user?.name}</h2>
                              <div className="col-md-5 pl-4 d-flex align-items-center">
                                <h6 className="d-flex ">
                                  {Array.from(
                                    Array(Math.ceil(user?.rating)).keys()
                                  ).map(() => (
                                    <div className="pr-2" style={{ color: "#E38226" }}>
                                      <i
                                        class="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  ))}
                                </h6>
                              </div>
                              <h3>$ {user?.price}</h3>
                            </div>
                            <div className="col-md-10 col-sm-12">
                              <h6 className="dot_text">
                                Diving at University of Illinois
                                Urbana-Champaign
                              </h6>
                            </div>

                            <div className="col-md-10 col-sm-12">
                              <h6 className="dot_text">{user?.about}</h6>
                            </div>
                          </div>
                          <div
                            className="col-md-5"
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              flexWrap: "wrap",
                            }}
                          >
                            {user?.skills?.map((skill) => (
                              <button className="skill_btn">{skill}</button>
                            ))}
                          </div>

                          <div className="col-md-7 d-flex align-items-center justify-content-end mb-3">
                            <button
                              className="book_btn "
                              onClick={() => CoachProfileHandler(user.coachId)}
                            >
                              View Profile
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : !loading ? (
                    <div className=" w-100 d-flex justify-content-center align-items-center">
                      <h4 className="text-white">
                        {msg.length > 0 ? msg : "Data Not Found"}
                      </h4>
                    </div>
                  ) : (
                    <div class=" w-100 d-flex justify-content-center align-items-center ">
                      <div class="spinner-border text-white" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}
                  {/* {list.length == 0 && (
                    <div style={{ width: "100%" }}>
                      <h1 style={{ color: "#fff" }}>No Data Found</h1>
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal */}
        {/* <div
          className={"modal fade" + (show ? " show d-block" : " d-none")}
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content" style={{ marginTop: "14rem" }}>
              <i
                class="fa fa-times-circle"
                aria-hidden="true"
                onClick={closehandler}
                style={{
                  fontSize: "22px",
                  color: "#fff !important",
                  padding: "7px",
                  textAlign: "right",
                  cursor: "pointer",
                }}
              ></i>
              <div className="modal-body">
                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Form.Control
                    required
                    type="text"
                    className="form-control signin_inpp mt-3"
                    placeholder="Enter Location"
                    name="address"
                    value={formData.address}
                    onChange={handleFormData}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ marginLeft: "65px" }}
                  >
                    Email is Required
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Form.Select
                    aria-label="Default select example"
                    className="form-control form-select select_boxx mt-3"
                    value={formData.sportId}
                    onChange={handleFormData}
                    name="sportId"
                    required
                  >
                    <option value="">Select Sport</option>
                    {getAllSports &&
                      getAllSports?.data.map((item, i) => {
                        return (
                          <option key={i} value={item.sportId}>
                            {item.sportName}
                          </option>
                        );
                      })}
                  </Form.Select>
                  <img className="set_arrowss" src={arrow} alt="arrow" />
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ marginLeft: "65px" }}
                  >
                    Please Select any Option
                  </Form.Control.Feedback>
                </Form.Group>

                <p
                  style={{
                    color: "#fff",
                    paddingLeft: "36px",
                    paddingTop: "10px",
                  }}
                >
                  Price Range
                </p>
                <div style={{ width: "83%", margin: "10px auto" }}>
                  <Slider
                    sx={{ width: "100%", color: "orange", margin: "0px auto" }}
                    getAriaLabel={() => "Temperature range"}
                    value={value}
                    onChange={handleChange}
                    valueLabelDisplay="auto"
                    getAriaValueText={valuetext}
                    min={0}
                    max={1000}
                  />
                </div>

                <button className="book_btnnn" onClick={filterSerachhandler}>
                  Filter
                </button>
              </div>
            </div>
          </div>
        </div> */}
      </Form>
      {/* Modal */}
    </>
  );
};

export default CoachSearch;
