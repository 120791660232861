import React, { useEffect, useState } from "react";
import "./BookingDetail.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetAllBookingDetailsByIdAction } from "../../redux/actions/GetAllBookingAction";
import { margin } from "@mui/system";

const BookingDetails = () => {
  const token = localStorage?.userData;
  let parsing = token ? JSON.parse(localStorage?.userData) : null;
  let usertype = parsing?.userType || null;

  const { getAllBookingDetailsById } = useSelector(
    (state) => state.getAllBookingDetailsById
  );
  const dispatch = useDispatch();

  const userData = getAllBookingDetailsById?.data;

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      let obj = {
        bookingId: +id,
      };
      dispatch(GetAllBookingDetailsByIdAction(obj));
    }
  }, [id]);

  return (
    <div style={{ background: "#212121  " }} className="pt-5">
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className=" bookingcard">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              margin: "20px",
            }}
          >
            <div>
              {usertype === 2 ? (
                <img
                  style={{
                    height: "200px",
                    width: "200px",
                    borderInlineStyle: " solid",
                    borderInlineColor: "rgba(227, 130, 38, 1)",
                  }}
                  className="card-img-top img-fluid"
                  src={userData?.studentImage}
                  alt="Card image cap"
                />
              ) : (
                <img
                  style={{
                    height: "200px",
                    width: "200px",
                    borderRadius: "50%",
                    borderInlineStyle: " solid",
                    borderInlineColor: "rgba(227, 130, 38, 1)",
                  }}
                  className="card-img-top img-fluid"
                  src={userData?.coachImage}
                  alt="Card image cap"
                />
              )}
            </div>

            <div className="card-title" style={{ marginTop: "20px" }}>
              {usertype === 2 ? (
                <h3>{userData?.studentName}</h3>
              ) : (
                <h3>{userData?.coachName}</h3>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                borderBottom: "1px solid rgba(227, 130, 38, 1)",
                width: "80%",
              }}
            ></div>
          </div>

          <div
            className="card-body"
            style={{ display: "flex", justifyContent: "space-evenly" }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "20px",
                }}
              >
                <div>
                  <i
                    class="fa fa-address-book fs-2 iconStyle"
                    aria-hidden="true"
                  ></i>
                </div>
                <div style={{ paddingLeft: "20px" }}>
                  <h5 className="card-text">contact</h5>
                  <h6>{userData?.phone}</h6>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "20px",
                }}
              >
                <div>
                  <i
                    class="fa fa-envelope fs-2 iconStyle"
                    aria-hidden="true"
                  ></i>
                </div>
                <div style={{ paddingLeft: "20px" }}>
                  <h5 className="card-text">Email</h5>
                  <h6>{userData?.email}</h6>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "20px",
                }}
              >
                <div>
                  <i
                    class="fa fa-user-plus fs-2 iconStyle"
                    aria-hidden="true"
                  ></i>
                </div>
                <div style={{ paddingLeft: "20px" }}>
                  <h5 className="card-text">addFriend</h5>
                  <h6>{userData?.addFriend}</h6>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "20px",
                }}
              >
                <div>
                  <i
                    class="fa fa-futbol-o fs-2 iconStyle"
                    aria-hidden="true"
                  ></i>
                </div>
                <div style={{ paddingLeft: "20px" }}>
                  <h5> sports</h5>
                  <h6>{userData?.sportName}</h6>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "20px",
                }}
              >
                <div>
                  <i
                    class="fa fa-map-marker fs-1 iconStyle"
                    aria-hidden="true"
                  ></i>
                </div>
                <div style={{ paddingLeft: "20px" }}>
                  <h5> location</h5>
                  <h6>{userData?.location}</h6>
                </div>
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "20px",
                }}
              >
                <div>
                  <i
                    class="fa fa-credit-card-alt fs-3 iconStyle"
                    aria-hidden="true"
                  ></i>
                </div>
                <div style={{ paddingLeft: "20px" }}>
                  <h5 className="card-text">payment</h5>
                  <h6>{userData?.paymentStatus}</h6>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "20px",
                }}
              >
                <div>
                  <i
                    class="fa fa-calendar fs-2 iconStyle"
                    aria-hidden="true"
                  ></i>
                </div>
                <div style={{ paddingLeft: "20px" }}>
                  <h5 className="card-text">days</h5>
                  <h6
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    {userData?.slotsList[0]?.weekDays?.map((item, i) => {
                      return (
                        <div key={i}>
                          {item === "1" && <h6>Mon</h6>}
                          {item === "2" && <h6>Tue</h6>}
                          {item === "3" && <h6>Wed</h6>}
                          {item === "4" && <h6>Thur</h6>}
                          {item === "5" && <h6>Fri</h6>}
                          {item === "6" && <h6>Sat</h6>}
                          {item === "7" && <h6>Sun</h6>}
                        </div>
                      );
                    })}
                  </h6>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "20px",
                }}
              >
                <div>
                  <i
                    class="fa fa-clock-o fs-2 iconStyle"
                    aria-hidden="true"
                  ></i>
                </div>
                <div style={{ paddingLeft: "20px" }}>
                  <h5 className="card-text">time</h5>
                  <h6>{userData?.slotsList[0]?.time}</h6>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "20px",
                }}
              >
                <div>
                  <i class="fa fa-inr fs-2 iconStyle" aria-hidden="true"></i>
                </div>
                <div style={{ paddingLeft: "20px" }}>
                  <h5>Price</h5>
                  <h6>{userData?.price}</h6>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "20px",
                }}
              >
                <div>
                  <i class="fa fa-square fs-2 iconStyle" aria-hidden="true"></i>
                </div>
                <div style={{ paddingLeft: "20px" }}>
                  <h5 className="card-text">lessons Duration</h5>
                  <h6>{userData?.lessonsDuration}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BookingDetails;
