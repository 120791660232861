import React, { useState } from 'react'
import { Col, Form } from "react-bootstrap";

const Payment = () => {

const defaultformData=()=>{

}
const[formData,setFormData]=useState();
    return (
        <div>
            <Form>
                <Form.Group className='col-md-8'>
                    <Form.Control
                        required
                        type="text"
                        className="input-control mt-4"
                        placeholder="PayPal ID"
                        name="paypalid"
                    // value={formData.newPassword}
                    // onChange={handleFormData}
                    />
                </Form.Group>


            </Form>
        </div>
    )
}
export default Payment;