import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Form, FormLabel, Row } from "react-bootstrap";
import { Rating } from "react-simple-star-rating";
import user from "../../assets/images/user.png";
import arrow from "../../assets/images/down.png";
import { useDispatch, useSelector } from "react-redux";
import { CreateReviewAction } from "../../redux/actions/ReviewAction";

const AddReviewModal = (props) => {
  const { profileDetail } = useSelector((state) => state.getProfileDetail);
  let {
    onHide,
    submitHandler,
    rating,
    setRating,
  } = props;
  const logo = profileDetail?.data?.profileImage;
  const handleRating = (rate) => {
    setRating({ ...rating, rating: rate });
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="booking_slot">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={
                  profileDetail?.data?.profileImage &&
                  profileDetail?.data?.profileImage
                }
                height="100px"
                width="100px"
                style={{
                  border: "4px solid rgb(227, 130, 38)",
                  borderRadius: "50%",
                }}
                alt={user}
              />

              <FormLabel style={{ color: "white" }}>
                {profileDetail?.data?.name}
              </FormLabel>
              <div className="my-3">
                <Rating
                  name="rating"
                  value={rating.rating}
                  onClick={handleRating}
                  initialValue={rating}
                />
              </div>
            </div>

            <Form.Group controlId="validationCustom02">
              <Form.Control
                as="textarea"
                rows={3}
                name="review"
                style={{ color: "white" }}
                type="text"
                className="form-control booking_inp mt-4"
                placeholder="Review type here...."
                value={rating.review}
                onChange={(e) =>
                  setRating({ ...rating, [e.target.name]: e.target.value })
                }
              />
            </Form.Group>

            <div className="row my-4">
              <Col as={Col} md="6">
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    className="main_ttButton ttButtonAnimation mx-lg-4"
                    onClick={() => submitHandler()}
                  >
                    Submit
                  </button>
                </div>
              </Col>
              <Col as={Col} md="6">
                <div className="d-flex justify-content-center">
                  <button
                    className="main_ttButton ttButtonAnimation mx-lg-4 "
                    onClick={onHide}
                  >
                    Cancel
                  </button>
                </div>
              </Col>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddReviewModal;
