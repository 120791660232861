import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Col } from "react-bootstrap";
import arrow from "../../assets/images/down.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteBookingAction,
  GetAllBookingAction,
  PreviousBookingAction,
  UpcomingBookingsAction,
} from "../../redux/actions/GetAllBookingAction";
import Slider from "@mui/material/Slider";
import { CreateReviewAction } from "../../redux/actions/ReviewAction";
import AddReviewModal from "../../components/Modal/AddReviewModal";
import PreviousBooking from "./previousBooking";
import UpcomingBooking from "./UpcomingBooking";
import BookingDetailsModal from "../../components/Modal/BookingDetailsModal";

const BookingList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = localStorage?.userData;
  let parsing = token ? JSON.parse(localStorage?.userData) : null;
  let usertype = parsing?.userType || null;

  const { previousBooking, upcomingBooking, loading } = useSelector(
    (state) => state.getAllBookingResponse
  );

  const data = previousBooking?.data;
  const response = previousBooking?.statusCode;
  const response1 = upcomingBooking?.statusCode;
  const sportId = localStorage.sportsId;
  const data1 = upcomingBooking?.data;

  const defautFormData = {
    page: 1,
    pageSize: 10,
  };
  const defaultRating = {
    reciverId: "",
    review: "",
    rating: "",
  };

  const [formData, setFormData] = useState(defautFormData);

  const [list, setList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [slotSwitch, setSlotSwitch] = useState(true);

  const [show, setShow] = useState(false);
  const [value, setValue] = useState([20, 200]);
  const [rating, setRating] = useState(defaultRating);

  const handleChange = (event, newValue) => {
    setFormData({
      ...formData,
      data,
      minPrice: newValue[0],
      maxPrice: newValue[1],
    });
    setValue(newValue);
  };
  const [bookingModal, setBookingModal] = useState(false);
  const handleFormData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // useEffect(() => {
  //   let obj = {
  //     page: 1,
  //     pageSize: 10,
  //   };
  //   dispatch(PreviousBookingAction(obj));
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const closehandler = () => {
    setShow(false);
  };

  const slotToggle = (slot) => {
    setSlotSwitch(slot);
  };

  useEffect(() => {
    if (!slotSwitch) {
      dispatch(UpcomingBookingsAction(formData));
    } else {
      dispatch(PreviousBookingAction(formData));
    }
  }, [slotSwitch]);

  useEffect(() => {
    if (response === 200) {
      setList(data);
    }
    if (response1 === 200) {
      setList(data1);
    }
  }, [previousBooking, upcomingBooking]);

  // const searchFilter = () => {
  //   console.log("hiiii");
  // };

  // useEffect(() => {
  //     let obj = {
  //         page: 1,
  //         pageSize: 100,
  //     };
  //     dispatch(GetAllSportsAction(obj));
  // }, []);

  const filterSerachhandler = (e) => {
    e.preventDefault();
    dispatch(GetAllBookingAction(formData));
    setShow(false);
  };

  const viewDetailHandler = (id) => {
    setBookingModal(true);
    window.location.hash = id;
    // navigate(`/appointmentList/${id}`);
  };

  const reviewHandler = (id) => {
    setRating({ ...rating, reciverId: id });
    setShowModal(true);
  };

  const deleteBookingHandler = (ids) => {
    let object = {
      bookingId: ids,
      bookingStatus: 2,
    };
    dispatch(DeleteBookingAction(object));
  };

  const approveBookingHandler = (ids) => {
    let object = {
      bookingId: ids,
      bookingStatus: 3,
    };
    dispatch(DeleteBookingAction(object));
  };

  const submitHandler = () => {
    if (rating.reciverId != "" && (rating.review != "" || rating.rating != ""))
      dispatch(CreateReviewAction(rating));
    setShowModal(false);
  };

  return (
    <div>
      {/* <Navbar /> */}

      <div className="container-fluid">
        <div className="row main_container ">
          <div className="col-md-12 search_container">
            <div className="row">
              <div className="col-md-12">
                <div className="button-section ">
                  <div className="d-flex flex-row">
                    <button
                      className={` ${
                        slotSwitch ? "main_ttButton_active" : "main_ttButton"
                      } ttButtonAnimation`}
                      onClick={() => slotToggle(true)}
                    >
                      Previous
                    </button>
                    <button
                      className={`${
                        !slotSwitch ? "main_ttButton_active" : "main_ttButton"
                      } ttButtonAnimation mx-lg-4`}
                      onClick={() => slotToggle(false)}
                    >
                      Upcoming
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* new format  */}
          <div className="col-md-12 search_container">
            {/* {showBooking.previousBooking && (
             
            )} */}
            {slotSwitch ? (
              <PreviousBooking
                list={list}
                viewDetailHandler={viewDetailHandler}
                reviewHandler={reviewHandler}
                usertype={usertype}
                loading={loading}
              />
            ) : (
              <UpcomingBooking
                list={list}
                viewDetailHandler={viewDetailHandler}
                approveBookingHandler={approveBookingHandler}
                deleteBookingHandler={deleteBookingHandler}
                usertype={usertype}
                loading={loading}
              />
            )}
          </div>
        </div>
      </div>
      {/* Modal */}
      {/*    
      <div
        className={"modal fade" + (show ? " show d-block" : " d-none")}
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content" style={{ marginTop: "14rem" }}>
        
            <i
              class="fa fa-times-circle"
              aria-hidden="true"
              onClick={closehandler}
              style={{
                fontSize: "22px",
                color: "#fff !important",
                padding: "7px",
                textAlign: "right",
                cursor: "pointer",
              }}
            ></i>
            <div className="modal-body">
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Control
                  required
                  type="text"
                  className="form-control signin_inpp mt-3"
                  placeholder="Enter Location"
                  name="address"
                  value={formData.address}
                  onChange={handleFormData}
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={{ marginLeft: "65px" }}
                >
                  Email is Required
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Select
                  aria-label="Default select example"
                  className="form-control form-select select_boxx mt-3"
                  value={formData.sportId}
                  onChange={handleFormData}
                  name="sportId"
                  required
                >
                  <option value="">Select Sport</option>
                  {getAllSports &&
                    getAllSports?.data.map((item, i) => {
                      return (
                        <option key={i} value={item.sportId}>
                          {item.sportName}
                        </option>
                      );
                    })}
                </Form.Select>
                <img className="set_arrowss" src={arrow} alt="arrow" />
               
                <Form.Control.Feedback
                  type="invalid"
                  style={{ marginLeft: "65px" }}
                >
                  Please Select any Option
                </Form.Control.Feedback>
              </Form.Group>

              <p
                style={{
                  color: "#fff",
                  paddingLeft: "36px",
                  paddingTop: "10px",
                }}
              >
                Price Range
              </p>
              <div style={{ width: "83%", margin: "10px auto" }}>
                <Slider
                  sx={{
                    width: "100%",
                    color: "orange",
                    margin: "0px auto",
                  }}
                  getAriaLabel={() => "Temperature range"}
                  value={value}
                  onChange={handleChange}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                  min={0}
                  max={1000}
                />
              </div>

              <button className="book_btnnn" onClick={filterSerachhandler}>
                Filter
              </button>
            </div>
          </div>
        </div>
      </div> */}
      <BookingDetailsModal
        show={bookingModal}
        modalHeader="Booking Detail"
        onHide={() => setBookingModal(false)}
      />
      <AddReviewModal
        show={showModal}
        rating={rating}
        setRating={setRating}
        onHide={() => setShowModal(false)}
        submitHandler={submitHandler}
      />

      {/* <Footer /> */}
    </div>
  );
};

export default BookingList;
