// Admin login
export const ADMIN_LOGIN_REQUEST = "ADMIN_LOGIN_REQUEST";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOGIN_FAIL = "ADMIN_LOGIN_FAIL";
export const ADMIN_LOGOUT = "ADMIN_LOGOUT";

// CHANGE PASSWORD
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";
export const CHANGE_PASSWORD_RESET = "CHANGE_PASSWORD_RESET";

// admin login
export const SOCIAL_LOGIN_REQUEST = "SOCIAL_LOGIN_REQUEST";
export const SOCIAL_LOGIN_SUCCESS = "SOCIAL_LOGIN_SUCCESS";
export const SOCIAL_LOGIN_FAIL = "SOCIAL_LOGIN_FAIL";
export const SOCIAL_LOGOUT = "SOCIAL_LOGOUT";

// Lessons SignUp
export const LESSONS_REGISTER_REQUEST = "LESSONS_REGISTER_REQUEST";
export const LESSONS_REGISTER_SUCCESS = "LESSONS_REGISTER_SUCCESS";
export const LESSONS_REGISTER_FAIL = "LESSONS_REGISTER_FAIL";
export const LESSONS_REGISTER_RESET = "LESSONS_REGISTER_RESET";

// get profile details
export const GET_PROFILE_DETAIL_REQUEST = "GET_PROFILE_DETAIL_REQUEST";
export const GET_PROFILE_DETAIL_SUCCESS = "GET_PROFILE_DETAIL_SUCCESS";
export const GET_PROFILE_DETAIL_FAIL = "GET_PROFILE_DETAIL_FAIL";
export const GET_PROFILE_DETAIL_RESET = "GET_PROFILE_DETAIL_RESET";

// update profile
export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";
export const UPDATE_RESPONSE_RESET = "UPDATE_RESPONSE_RESET";

// Upload Photo
export const UPLOAD_PHOTO_REQUEST = "UPLOAD_PHOTO_REQUEST";
export const UPLOAD_PHOTO_SUCCESS = "UPLOAD_PHOTO_SUCCESS";
export const UPLOAD_PHOTO_FAIL = "UPLOAD_PHOTO_FAIL";
export const UPLOAD_PHOTO_RESET = "UPLOAD_PHOTO_RESET";

// Register Upload Photo
export const REGISTER_UPLOAD_PHOTO_REQUEST = "REGISTER_UPLOAD_PHOTO_REQUEST";
export const REGISTER_UPLOAD_PHOTO_SUCCESS = "REGISTER_UPLOAD_PHOTO_SUCCESS";
export const REGISTER_UPLOAD_PHOTO_FAIL = "REGISTER_UPLOAD_PHOTO_FAIL";
export const REGISTER_UPLOAD_PHOTO_RESET = "REGISTER_UPLOAD_PHOTO_RESET";

// ALL SPORTS
export const GET_ALL_SPORTS_REQUEST = "GET_ALL_SPORTS_REQUEST";
export const GET_ALL_SPORTS_SUCCESS = "GET_ALL_SPORTS_SUCCESS";
export const GET_ALL_SPORTS_FAIL = "GET_ALL_SPORTS_FAIL";
export const GET_ALL_SPORTS_RESET = "GET_ALL_SPORTS_RESET";

// Get All Coach
export const GET_COACH_REQUEST = "GET_COACH_REQUEST";
export const GET_COACH_SUCCESS = "GET_COACH_SUCCESS";
export const GET_COACH_FAIL = "GET_COACH_FAIL";
export const GET_COACH_RESET = "GET_COACH_RESET";

// Get All Coach
export const GET_COACHBYID_REQUEST = "GET_COACHBYID_REQUEST";
export const GET_COACHBYID_SUCCESS = "GET_COACHBYID_SUCCESS";
export const GET_COACHBYID_FAIL = "GET_COACHBYID_FAIL";
export const GET_COACHBYID_RESET = "GET_COACHBYID_RESET";

// Get Coach Profile
export const GET_COACH_PROFILE_REQUEST = "GET_COACH_PROFILE_REQUEST";
export const GET_COACH_PROFILE_SUCCESS = "GET_COACH_PROFILE_SUCCESS";
export const GET_COACH_PROFILE_FAIL = "GET_COACH_PROFILE_FAIL";
export const GET_COACH_PROFILE_RESET = "GET_COACH_PROFILE_RESET";

// Get Coach Slots
export const GET_COACH_SLOTS_REQUEST = "GET_COACH_SLOTS_REQUEST";
export const GET_COACH_SLOTS_SUCCESS = "GET_COACH_SLOTS_SUCCESS";
export const GET_COACH_SLOTS_FAIL = "GET_COACH_SLOTS_FAIL";
export const GET_COACH_SLOTS_RESET = "GET_COACH_SLOTS_RESET";

// Contact Us
export const CONTACT_US_REQUEST = "CONTACT_US_REQUEST";
export const CONTACT_US_SUCCESS = "CONTACT_US_SUCCESS";
export const CONTACT_US_FAIL = "CONTACT_US_FAIL";
export const CONTACT_US_RESET = "CONTACT_US_RESET";

// Create Booking
export const CREATE_BOOKING_REQUEST = "CREATE_BOOKING_REQUEST";
export const CREATE_BOOKING_SUCCESS = "CREATE_BOOKING_SUCCESS";
export const CREATE_BOOKING_FAIL = "CREATE_BOOKING_FAIL";
export const CREATE_BOOKING_RESET = "CREATE_BOOKING_RESET";

//  Confirmation lesson completion
export const CONFIRMATION_LESSON_COMPLETION_REQUEST =
  "CONFIRMATION_LESSON_COMPLETION_REQUEST";
export const CONFIRMATION_LESSON_COMPLETION_SUCCESS =
  "CONFIRMATION_LESSON_COMPLETION_SUCCESS";
export const CONFIRMATION_LESSON_COMPLETION_FAIL =
  "CONFIRMATION_LESSON_COMPLETION_FAIL";
export const CONFIRMATION_LESSON_COMPLETION_RESET =
  "CONFIRMATION_LESSON_COMPLETION_RESET";

// Delete Booking
export const DELETE_BOOKING_REQUEST = "DELETE_BOOKING_REQUEST";
export const DELETE_BOOKING_SUCCESS = "DELETE_BOOKING_SUCCESS";
export const DELETE_BOOKING_FAIL = "DELETE_BOOKING_FAIL";

// Previous Booking
export const PREVIOUS_BOOKING_REQUEST = "PREVIOUS_BOOKING_REQUEST";
export const PREVIOUS_BOOKING_SUCCESS = "PREVIOUS_BOOKING_SUCCESS";
export const PREVIOUS_BOOKING_FAIL = "PREVIOUS_BOOKING_FAIL";
export const PREVIOUS_BOOKING_RESET = "PREVIOUS_BOOKING_RESET";

// Upcoming Booking
export const UPCOMING_BOOKING_REQUEST = "UPCOMING_BOOKING_REQUEST";
export const UPCOMING_BOOKING_SUCCESS = "UPCOMING_BOOKING_SUCCESS";
export const UPCOMING_BOOKING_FAIL = "UPCOMING_BOOKING_FAIL";
export const UPCOMING_BOOKING_RESET = "UPCOMING_BOOKING_RESET";

// Create Payment
export const CREATE_PAYMENT_REQUEST = "CREATE_PAYMENT_REQUEST";
export const CREATE_PAYMENT_SUCCESS = "CREATE_PAYMENT_SUCCESS";
export const CREATE_PAYMENT_FAIL = "CREATE_PAYMENT_FAIL";
export const CREATE_PAYMENT_RESET = "CREATE_PAYMENT_RESET";

// Capture Payment
export const ADDPAYPALID_REQUEST = "ADDPAYPALID_REQUEST";
export const ADDPAYPALID_SUCCESS = "ADDPAYPALID_SUCCESS";
export const ADDPAYPALID_FAIL = "ADDPAYPALID_FAIL";

// AddPayPalId 
export const CAPTURE_PAYMENT_REQUEST = "CAPTURE_PAYMENT_REQUEST";
export const CAPTURE_PAYMENT_SUCCESS = "CAPTURE_PAYMENT_SUCCESS";
export const CAPTURE_PAYMENT_FAIL = "CAPTURE_PAYMENT_FAIL";

// Get By near actions

export const GET_NEAR_BY_VENUE_REQUEST = "GET_NEAR_BY_VENUE_REQUEST";
export const GET_NEAR_BY_VENUE_SUCCESS = "GET_NEAR_BY_VENUE_SUCCESS";
export const GET_NEAR_BY_VENUE_FAIL = "GET_NEAR_BY_VENUE_FAIL";

// upload file

export const UPLOAD_FILE_REQUEST = "UPLOAD_FILE_REQUEST";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAIL = "UPLOAD_FILE_FAIL";

// upload documnets
export const UPLOAD_DOCUMENT_REQUEST = "UPLOAD_DOCUMENT_REQUEST";
export const UPLOAD_DOCUMENT_SUCCESS = "UPLOAD_DOCUMENT_SUCCESS";
export const UPLOAD_DOCUMENT_FAIL = "UPLOAD_DOCUMENT_FAIL";

// Get All Booking
export const GET_ALL_BOOKING_REQUEST = "GET_ALL_BOOKING_REQUEST";
export const GET_ALL_BOOKING_SUCCESS = "GET_ALL_BOOKING_SUCCESS";
export const GET_ALL_BOOKING_FAIL = "GET_ALL_BOOKING_FAIL";
export const GET_ALL_BOOKING_RESET = "GET_ALL_BOOKING_RESET";

// Get All BookingID
export const GET_ALL_BOOKING_ID_REQUEST = "GET_ALL_BOOKING_ID_REQUEST";
export const GET_ALL_BOOKING_ID_SUCCESS = "GET_ALL_BOOKING_ID_SUCCESS";
export const GET_ALL_BOOKING_ID_FAIL = "GET_ALL_BOOKING_ID_FAIL";

// Get All Booking
export const GET_ALL_SLOTS_REQUEST = "GET_ALL_SLOTS_REQUEST";
export const GET_ALL_SLOTS_SUCCESS = "GET_ALL_SLOTS_SUCCESS";
export const GET_ALL_SLOTS_FAIL = "GET_ALL_SLOTS_FAIL";

//  create Booking slot
export const CREATE_BOOKING_SLOT_REQUEST = "CREATE_BOOKING_SLOT_REQUEST";
export const CREATE_BOOKING_SLOT_SUCCESS = "CREATE_BOOKING_SLOT_SUCCESS";
export const CREATE_BOOKING_SLOT_FAIL = "CREATE_BOOKING_SLOT_FAIL";
export const CREATE_BOOKING_SLOT_RESPONSE = "CREATE_BOOKING_SLOT_RESPONSE";

//  get By Id Booking slot
export const GETBYID_BOOKING_SLOT_REQUEST = "GETBYID_BOOKING_SLOT_REQUEST";
export const GETBYID_BOOKING_SLOT_SUCCESS = "GETBYID_BOOKING_SLOT_SUCCESS";
export const GETBYID_BOOKING_SLOT_FAIL = "GETBYID_BOOKING_SLOT_FAIL";
export const GETBYID_BOOKING_SLOT_RESPONSE = "GETBYID_BOOKING_SLOT_RESPONSE";

// Edit Booking slot
export const EDIT_BOOKING_SLOT_REQUEST = "EDIT_BOOKING_SLOT_REQUEST";
export const EDIT_BOOKING_SLOT_SUCCESS = "EDIT_BOOKING_SLOT_SUCCESS";
export const EDIT_BOOKING_SLOT_FAIL = "EDIT_BOOKING_SLOT_FAIL";
export const EDIT_BOOKING_SLOT_RESPONSE = "EDIT_BOOKING_SLOT_RESPONSE";

// Delete Booking slot
export const DELETE_BOOKING_SLOT_REQUEST = "DELETE_BOOKING_SLOT_REQUEST";
export const DELETE_BOOKING_SLOT_SUCCESS = "DELETE_BOOKING_SLOT_SUCCESS";
export const DELETE_BOOKING_SLOT_FAIL = "DELETE_BOOKING_SLOT_FAIL";
export const DELETE_BOOKING_SLOT_RESPONSE = "DELETE_BOOKING_SLOT_RESPONSE";

// Confrim Booking
export const CONFIRM_BOOKING_LESSON_REQUEST = "CONFIRM_BOOKING_SLOT_REQUEST";
export const CONFIRM_BOOKING_LESSON_SUCCESS = "CONFIRM_BOOKING_SLOT_SUCCESS";
export const CONFIRM_BOOKING_LESSON_FAIL = "CONFIRM_BOOKING_SLOT_FAIL";

//Coach Venue
export const GET_ALL_VENUE_REQUEST = "GET_ALL_VENUE_REQUEST";
export const GET_ALL_VENUE_SUCCESS = "GET_ALL_VENUE_SUCCESS";
export const GET_ALL_VENUE_FAIL = "GET_ALL_VENUE_FAIL";
export const GET_ALL_VENUE_RESPONSE = "GET_ALL_VENUE_RESPONSE";

// all Venues
export const GET_ALL_COACH_VENUE_REQUEST = "GET_ALL_COACH_VENUE_REQUEST";
export const GET_ALL_COACH_VENUE_SUCCESS = "GET_ALL_COACH_VENUE_SUCCESS";
export const GET_ALL_COACH_VENUE_FAIL = "GET_ALL_COACH_VENUE_FAIL";
export const GET_ALL_COACH_VENUE_RESPONSE = "GET_ALL_COACH_VENUE_RESPONSE";

// get Venue By Id
export const GET_VENUE_BY_ID_REQUEST = "GET_VENUE_BY_ID_REQUEST";
export const GET_VENUE_BY_ID_SUCCESS = "GET_VENUE_BY_ID_SUCCESS";
export const GET_VENUE_BY_ID_FAIL = "GET_VENUE_BY_ID_FAIL";

//Review
export const GET_ALL_REVIEW_REQUEST = " GET_ALL_REVIEW_REQUEST";
export const GET_ALL_REVIEW_SUCCESS = "GET_ALL_REVIEW_SUCCESS";
export const GET_ALL_REVIEW_FAIL = "GET_ALL_REVIEW_FAIL";

//CreateReview
export const CREATE_REVIEW_REQUEST = " CREATE_REVIEW_REQUEST";
export const CREATE_REVIEW_SUCCESS = "CREATE_REVIEW_SUCCESS";
export const CREATE_REVIEW_FAIL = "CREATE_REVIEW_FAIL";

//PublicReview
export const PUBLIC_REVIEW_REQUEST = " PUBLIC_REVIEW_REQUEST";
export const PUBLIC_REVIEW_SUCCESS = "PUBLIC_REVIEW_SUCCESS";
export const PUBLIC_REVIEW_FAIL = "PUBLIC_REVIEW_FAIL";


//EmailTemplate
export const EMAIL_TEMPLATE_REQUEST = " EMAIL_TEMPLATE_REQUEST";
export const EMAIL_TEMPLATE_SUCCESS = "CEMAIL_TEMPLATESUCCESS";
export const EMAIL_TEMPLATE_FAIL = "EMAIL_TEMPLATE_FAIL";
// delete Coach Venue
export const DELETE_VENUE_REQUEST = "DELETE_VENUE_REQUEST";
export const DELETE_VENUE_SUCCESS = "DELETE_VENUE_SUCCESS";
export const DELETE_VENUE_FAIL = "DELETE_VENUE_FAIL";
export const DELETE_VENUE_RESET = "DELETE_VENUE_RESET";

// GET_BY_CHAT_ID
export const GET_BY_CHAT_ID_REQUEST = "GET_BY_CHAT_ID_REQUEST";
export const GET_BY_CHAT_ID_SUCCESS = "GET_BY_CHAT_ID_SUCCESS";
export const GET_BY_CHAT_ID_FAIL = "GET_BY_CHAT_ID_FAIL";
export const GET_BY_CHAT_ID_RESET = "GET_BY_CHAT_ID_RESET";

// GET_BY_CHAT_ID
export const GET_BOOKING_USER_REQUEST = "GET_BOOKING_USER_REQUEST";
export const GET_BOOKING_USER_SUCCESS = "GET_BOOKING_USER_SUCCESS";
export const GET_BOOKING_USER_FAIL = "GET_BOOKING_USER_FAIL";
export const GET_BOOKING_USER_RESET = "GET_BOOKING_USER_RESET";

// delete child

export const DELETE_CHILD_REQUEST = "DELETE_CHILD_REQUEST";
export const DELETE_CHILD_SUCCESS = "DELETE_CHILD_SUCCESS";
export const DELETE_CHILD_FAIL = "DELETE_CHILD_FAIL";
export const DELETE_CHILD_RESET = "DELETE_CHILD_RESET";

// edit child handler
export const GET_CHILD_BY_ID_REQUEST = "GET_CHILD_BY_ID_REQUEST";
export const GET_CHILD_BY_ID_SUCCESS = "GET_CHILD_BY_ID_SUCCESS";
export const GET_CHILD_BY_ID_FAIL = "GET_CHILD_BY_ID_FAIL";
export const GET_CHILD_BY_ID_RESET = "GET_CHILD_BY_ID_RESET";

// update handler
export const UPDATE_CHILD_REQUEST = "UPDATE_CHILD_REQUEST";
export const UPDATE_CHILD_SUCCESS = "UPDATE_CHILD_SUCCESS";
export const UPDATE_CHILD_FAIL = "UPDATE_CHILD_FAIL";
export const UPDATE_CHILD_RESET = "UPDATE_CHILD_RESET";

// upload media file
export const USER_UPLOADFILE_REQUEST = "  USER_UPLOADFILE_REQUEST";
export const USER_UPLOADFILE_SUCCESS = "  USER_UPLOADFILE_SUCCESS";
export const USER_UPLOADFILE_FAIL = "  USER_UPLOADFILE_FAIL";
export const USER_UPLOADFILE_RESET = "  USER_UPLOADFILE_RESET";

// get all skills
export const GET_ALL_SKILLS_REQUEST = "GET_ALL_SKILLS_REQUEST";
export const GET_ALL_SKILLS_SUCCESS = "GET_ALL_SKILLS_SUCCESS";
export const GET_ALL_SKILLS_FAIL = "GET_ALL_SKILLS_FAIL";
export const GET_ALL_SKILLS_RESET = "GET_ALL_SKILLS_RESET";

// get  coach by venueId
export const GET_COACH_BY_VENUE_ID_REQUEST = "GET_COACH_BY_VENUE_ID_REQUEST";
export const GET_COACH_BY_VENUE_ID_SUCCESS = "GET_COACH_BY_VENUE_ID_SUCCESS";
export const GET_COACH_BY_VENUE_ID_FAIL = "GET_COACH_BY_VENUE_ID_FAIL";
export const GET_COACH_BY_VENUE_ID_RESET = "GET_COACH_BY_VENUE_ID_RESET";

// get  coach by radius
export const GET_COACH_BY_RADIUS_REQUEST = "GET_COACH_BY_RADIUS_REQUEST";
export const GET_COACH_BY_RADIUS_SUCCESS = "GET_COACH_BY_RADIUS_SUCCESS";
export const GET_COACH_BY_RADIUS_FAIL = "GET_COACH_BY_RADIUS_FAIL";
export const GET_COACH_BY_RADIUS_RESET = "GET_COACH_BY_RADIUS_RESET";
