import React from "react";
import moment from "moment";
const Weekly = ({
  getAllSlots,
  editSlotHandler,
  deleteSlotHandler,
  loading,
}) => {
  return (
    <>
      <div className="col-md-12">
        {getAllSlots?.data?.length > 0 ? (
          getAllSlots?.data?.map((user) => {
            // let fromtime = new Date(
            //   user.fromTime
            // ).toLocaleTimeString();
            // let toTime = new Date(user.toTime).toLocaleTimeString();
            return (
              <>
                <div className="name_section">
                  <div className="coach_price">
                    <div className="coach_price_label">
                      <h3>Start Time</h3>
                    </div>
                    <div className="coach_full_price">
                      <h4 className="text-white"> {user.fromTime} </h4>
                    </div>
                  </div>
                  <div
                    style={{
                      borderLeft: "1px solid #575757",
                      maxHeight: "100%",
                    }}
                  ></div>
                  <div className="coach_price">
                    <div className="coach_price_label">
                      <h3>End Time</h3>
                    </div>
                    <div className="coach_full_price">
                      <h4 className="text-white"> {user.toTime} </h4>
                    </div>
                  </div>
                  <div
                    style={{
                      borderLeft: "1px solid #575757",
                      maxHeight: "100%",
                    }}
                  ></div>
                  <div className="coach_price">
                    <div className="coach_price_label">
                      <h3>Slot Date</h3>
                    </div>
                    <div className="coach_full_price">
                      <h4 className="text-white">
                        {" "}
                        {moment(user.slotDate).format("YYYY-MM-DD")}{" "}
                      </h4>
                    </div>
                  </div>
                  <div
                    style={{
                      borderLeft: "1px solid #575757",
                      maxHeight: "100%",
                    }}
                  ></div>
                  <div className="coach_price " style={{ width: "50%" }}>
                    <div className="coach_price_label">
                      <h3>Availability</h3>
                    </div>
                    <div className="d-flex">
                      {user?.weekDays?.map((item, i) => {
                        return (
                          <div key={i} className="form-check mx-2 ">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              checked="true"
                              id="flexCheckDefault"
                              disabled
                            />
                            <label
                              className="form-check-label text-white"
                              for="flexCheckDefault"
                            >
                              {item === "1" && <p>Mon</p>}
                              {item === "2" && <p>Tues</p>}
                              {item === "3" && <p>Wed</p>}
                              {item === "4" && <p>Thurs</p>}
                              {item === "5" && <p>Fri</p>}
                              {item === "6" && <p>Sat</p>}
                              {item === "7" && <p>Sun</p>}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    style={{
                      borderLeft: "1px solid #575757",
                      maxHeight: "100%",
                    }}
                  ></div>
                  <div className="w-25 d-flex justify-content-around align-items-center ">
                    <button
                      className="iconButton iconButtonAnimation"
                      onClick={() => editSlotHandler(user.slotId)}
                    >
                      <i
                        className="fa fa-pencil-square-o"
                        aria-hidden="true"
                      ></i>
                    </button>

                    <button
                      className="iconButton iconButtonAnimation"
                      onClick={() => deleteSlotHandler(user.slotId)}
                    >
                      <i className="fa fa-trash-o" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </>
            );
          })
        ) : !loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <h4 className="text-white">Data Not Found</h4>
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            <div className="spinner-border text-light" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Weekly;
