import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import logo from "../assets/images/logo.png"
const Footer = () => {
  return (
    <div className="container-fluid footer  col-auto">
      <div className="footer-content d-flex justify-content-center align-items-end">
        <div className="container col-md-lg-2">
          <img src={logo} alt="logo" style={{ width: "90%" }} />
        </div>

        <div className="footer_desc container col-8 align-items-end">
          <p>
            Top Tier Lessons is an online platform designed for Student Athletes
          </p>
          <div className="container col-md-lg-8">
            <div className="row" style={{ justifyContent: "center" }}>
              <div className="col-md-3 col-lg-2 col-xs-4 footer_links">
                <p>Parents</p>
              </div>
              <div className="col-md-3 col-lg-2  col-xs-4 footer_links">
                <p>Student Athletes</p>
              </div>
              <div className="col-md-3  col-lg-1 col-xs-4 footer_links">
                <p>Blog</p>
              </div>
              <div className="col-md-3 col-lg-2 col-xs-4 footer_links">
                <Link to="/terms" className="underline_text">
                  <p>Terms & Conditions</p>
                </Link>
              </div>
              <div className="col-md-3 col-lg-1 col-xs-4 footer_links">
                <Link to="/contactUs" className="underline_text">
                  <p>Contact</p>
                </Link>
              </div>
              <div className="col-md-3 col-lg-2 col-xs-4 footer_links">
                <Link to="/privacy" className="underline_text">
                  <p>Privacy Policy</p>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="container col-2">
          <div className="row footer_icons" style={{ justifyContent: "center" }}>
            <div className="col-md-2 col-xs-3 col-sm-3  footer_links">
              <i className="fa fa-facebook" aria-hidden="true"></i>
            </div>
            <div className="col-md-2 col-xs-3 col-sm-3 footer_links">
              <i className="fa fa-twitter" aria-hidden="true"></i>
            </div>
            <div className="col-md-2 col-xs-3 col-sm-3 footer_links">
              <i className="fa fa-instagram" aria-hidden="true"></i>
            </div>
            <div className="col-md-2 col-xs-3 col-sm-3 footer_links">
              <i className="fa fa-linkedin" aria-hidden="true"></i>
            </div>
          </div>
        </div>


      </div>
      <div className="bottom-border">

      </div>
      <div className="col-md-12 col-xs-12 col-sm-12 footer_links d-flex  justify-content-center">
        <h6>copyright </h6>
        <i class="fa fa-copyright" aria-hidden="true"></i>
      </div>
    </div>
  );
};

export default Footer;
