import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import { Col, Form, FormLabel } from "react-bootstrap";
import arrow from "../../assets/images/down.png";
import filter from "../../assets/images/filter.png";
import line from "../../assets/images/line.png";
import search from "../../assets/images/search.png";
import Footer from "../../components/Footer";
import { GetAllSlotsAction } from "../../redux/actions/GetTImeSlotsAction";
import { useDispatch, useSelector } from "react-redux";
import AddSlotModal from "../../components/Modal/AddSlotModal";
import moment from "moment";
import {
  CreateBookingSlotAction,
  deleteBookingSlotAction,
  deleteBookingSlotResponse,
  EditBookingSlotAction,
  EditBookingSlotResponse,
  getByIdBookingSlotAction,
  ResetBookingSlotResponse,
} from "../../redux/actions/Bookings";
import { toast } from "react-toastify";
import Recuring from "../../components/BookingComponent/Recuring";
import Weekly from "../../components/BookingComponent/Weekly";
import BookingDetailsModal from "../../components/Modal/BookingDetailsModal";

const BookingSlot = () => {
  let { getAllSlots, loading } = useSelector((state) => state.getAllSlots);
  let {
    createBookingSlot,
    deleteBookingSlot,
    getByIdBookingSlot,

    editBookingSlot,
  } = useSelector((state) => state.createBookingResponse);

  const defautFormData = {
    fromTime: "",
    toTime: "",
    weekDays: [],
    ageGroup: "",
    isRecurring: true,
    slotDate: "",
  };
  const defautWeek = {
    Mon: false,
    Tues: false,
    Wed: false,
    Thur: false,
    Fri: false,
    Sat: false,
    Sun: false,
  };

  const [formData, setFormData] = useState(defautFormData);

  const [modalHeader, setModalHeader] = useState();
  const [modalShow, setModalShow] = useState(false);

  const [slotSwitch, setSlotSwitch] = useState(true);
  const [checked, setChecked] = useState(defautWeek);

  const [validated, setValidated] = useState(false);

  const dispatch = useDispatch();

  const handleOnChange = (e) => {
    if (e.target.name === "slotDate") {
      setFormData({
        ...formData,
        [e.target.name]: moment.utc(moment(e.target.value).utc()).format(),
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    setFormData({ ...formData, isRecurring: slotSwitch });
  }, [slotSwitch]);

  const handleOnChangecheckbox = (e, val) => {
    setChecked({ ...checked, [e.target.name]: val });
  };

  function getList() {
    let obj = {
      page: 1,
      pageSize: 10,
      isRecurring: slotSwitch,
    };
    dispatch(GetAllSlotsAction(obj));
  }

  useEffect(() => {
    getList();
  }, [slotSwitch]);

  useEffect(() => {
    if (createBookingSlot) {
      getList();
    }
    if (deleteBookingSlot) {
      getList();
    }
    if (editBookingSlot) {
      getList();
    }
  }, [createBookingSlot, deleteBookingSlot, editBookingSlot]);

  useEffect(() => {
    if (createBookingSlot?.statusCode === 200) {
      setModalShow(false);
      toast.success(createBookingSlot?.returnMessage[0]);
      dispatch(ResetBookingSlotResponse());
    }
    if (deleteBookingSlot?.statusCode === 200) {
      toast.success(deleteBookingSlot?.returnMessage[0]);
      dispatch(deleteBookingSlotResponse());
    }
    if (getByIdBookingSlot?.statusCode === 200) {
      let weekdaysPreFilled = { ...checked };
      setValidated(false);
      setFormData({
        ...formData,
        ageGroup: getByIdBookingSlot?.data?.ageGroup,
        coachId: getByIdBookingSlot?.data?.coachId,
        fromTime: getByIdBookingSlot?.data?.fromTime,
        slotId: getByIdBookingSlot?.data?.slotId,
        toTime: getByIdBookingSlot?.data?.toTime,
        slotDate: getByIdBookingSlot?.data?.slotDate,
      });

      if (getByIdBookingSlot?.data?.weekDays) {
        let values = Object.values(getByIdBookingSlot?.data?.weekDays).map(
          (key) => {
            if (key === "1") {
              weekdaysPreFilled["Mon"] = true;
            } else if (key === "2") {
              weekdaysPreFilled["Tues"] = true;
            } else if (key === "3") {
              weekdaysPreFilled["Wed"] = true;
            } else if (key === "4") {
              weekdaysPreFilled["Thur"] = true;
            } else if (key === "5") {
              weekdaysPreFilled["Fri"] = true;
            } else if (key === "6") {
              weekdaysPreFilled["Sat"] = true;
            } else if (key === "7") {
              weekdaysPreFilled["Sun"] = true;
            }
          }
        );
        setChecked(weekdaysPreFilled);
      }
    }
    if (editBookingSlot?.statusCode === 200) {
      setModalShow(false);
      toast.success(editBookingSlot?.returnMessage[0]);
      dispatch(EditBookingSlotResponse());
    }
  }, [
    createBookingSlot,
    deleteBookingSlot,
    getByIdBookingSlot,
    editBookingSlot,
  ]);

  const addSlotHandler = (typeAdd) => {
    let slotPayload = { ...formData };

    if (!typeAdd) {
      if (
        slotPayload.fromTime != "" &&
        slotPayload.toTime != "" &&
        slotPayload.ageGroup != "" &&
        slotPayload.slotDate != ""
      ) {
        dispatch(CreateBookingSlotAction(slotPayload));
      }
    } else {
      if (checked) {
        let values = Object.entries(checked).map((key) => {
          if (key[0] == "Mon") {
            if (key[1]) {
              return 1;
            }
          } else if (key[0] == "Tues") {
            if (key[1]) {
              return 2;
            }
          } else if (key[0] == "Wed") {
            if (key[1]) {
              return 3;
            }
          } else if (key[0] == "Thur") {
            if (key[1]) {
              return 4;
            }
          } else if (key[0] == "Fri") {
            if (key[1]) {
              return 5;
            }
          } else if (key[0] == "Sat") {
            if (key[1]) {
              return 6;
            }
          } else if (key[0] == "Sun") {
            if (key[1]) {
              return 7;
            }
          }
        });
        const theseDays = values.filter(function (element) {
          return element !== undefined;
        });

        if (slotPayload) {
          slotPayload = { ...slotPayload, weekDays: theseDays };
        }
      }
      if (
        slotPayload.fromTime != "" &&
        slotPayload.toTime != "" &&
        slotPayload.ageGroup != "" &&
        slotPayload.weekDays.length > 0
      ) {
        dispatch(CreateBookingSlotAction(slotPayload));
      }
    }
  };

  const updatingSlotHandler = () => {
    if (getByIdBookingSlot?.statusCode === 200) {
      let slotPayload = { ...formData };
      if (checked) {
        let values = Object.entries(checked).map((key) => {
          if (key[0] == "Mon") {
            if (key[1]) {
              return 1;
            }
          } else if (key[0] == "Tues") {
            if (key[1]) {
              return 2;
            }
          } else if (key[0] == "Wed") {
            if (key[1]) {
              return 3;
            }
          } else if (key[0] == "Thur") {
            if (key[1]) {
              return 4;
            }
          } else if (key[0] == "Fri") {
            if (key[1]) {
              return 5;
            }
          } else if (key[0] == "Sat") {
            if (key[1]) {
              return 6;
            }
          } else if (key[0] == "Sun") {
            if (key[1]) {
              return 7;
            }
          }
        });
        const theseDays = values.filter(function (element) {
          return element !== undefined;
        });

        if (theseDays) {
          slotPayload = {
            ...slotPayload,
            weekDays: theseDays,
            slotId: getByIdBookingSlot?.data?.slotId,
          };
        }
      }

      if (
        slotPayload.fromTime != "" &&
        slotPayload.toTime != "" &&
        slotPayload.ageGroup != "" &&
        slotPayload.weekDays.length > 0
      ) {
        dispatch(EditBookingSlotAction(slotPayload));
      }
    }
  };

  const submitHandler = (e, modalHeader) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (modalHeader === "Add Booking Slot") {
      addSlotHandler(slotSwitch);
    }
    if (modalHeader === "Edit Booking Slot") {
      updatingSlotHandler();
    }
    setValidated(true);
  };

  const AddSlotModalHandler = () => {
    setValidated(false);
    setFormData({
      ...formData,
      fromTime: "",
      toTime: "",
      weekDays: [],
      ageGroup: "",
      slotDate: "",
    });
    setChecked({
      ...checked,
      Mon: false,
      Tues: false,
      Wed: false,
      Thur: false,
      Fri: false,
      Sat: false,
      Sun: false,
    });
    setModalHeader("Add Booking Slot");
    dispatch(ResetBookingSlotResponse());
    setModalShow(true);
  };

  const editSlotHandler = (id) => {
    setModalHeader("Edit Booking Slot");
    setValidated(false);
    setFormData({
      ...formData,
      fromTime: "",
      toTime: "",
      weekDays: [],
      ageGroup: "",
      slotDate: "",
    });
    setChecked({
      ...checked,
      Mon: false,
      Tues: false,
      Wed: false,
      Thur: false,
      Fri: false,
      Sat: false,
      Sun: false,
    });
    dispatch(
      getByIdBookingSlotAction({
        slotId: id,
      })
    );

    setModalShow(true);
  };

  const deleteSlotHandler = (id) => {
    if (window.confirm("Are you sure you want to delete")) {
      dispatch(
        deleteBookingSlotAction({
          slotId: id,
        })
      );
    }
  };

  const slotToggle = (slot) => {
    setSlotSwitch(slot);
  };

  return (
    <>
      <div>
        <Navbar />
        <div className="container-fluid">
          <div className="row main_container">
            <div className="col-md-12 search_container">
              <div className="">
                <div className="row">
                  <div className="col-md-12">
                    <div className="button-section d-flex justify-content-between align-items-center ">
                      <div className="d-flex flex-row  ">
                        <button
                          className={`${
                            slotSwitch
                              ? "main_ttButton_active"
                              : "main_ttButton"
                          } ttButtonAnimation`}
                          onClick={() => slotToggle(true)}
                        >
                          Recuring
                        </button>
                        <button
                          className={`${
                            !slotSwitch
                              ? "main_ttButton_active"
                              : "main_ttButton"
                          } ttButtonAnimation mx-lg-4`}
                          onClick={() => slotToggle(false)}
                        >
                          Weekly
                        </button>
                      </div>
                      <div>
                        <button
                          className="main_ttButton ttButtonAnimation"
                          onClick={() =>
                            AddSlotModalHandler(slotSwitch ? true : false)
                          }
                        >
                          ADD
                        </button>
                      </div>
                    </div>
                  </div>
                  {slotSwitch ? (
                    <Recuring
                      // slotSwitch={slotSwitch}
                      getAllSlots={getAllSlots}
                      editSlotHandler={editSlotHandler}
                      deleteSlotHandler={deleteSlotHandler}
                      loading={loading}
                    />
                  ) : (
                    <Weekly
                      getAllSlots={getAllSlots}
                      editSlotHandler={editSlotHandler}
                      deleteSlotHandler={deleteSlotHandler}
                      loading={loading}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>

      <AddSlotModal
        slotSwitch={slotSwitch}
        show={modalShow}
        modalHeader={modalHeader}
        onHide={() => setModalShow(false)}
        formData={formData}
        handleOnChange={handleOnChange}
        checked={checked}
        validation={validated}
        handleOnChangecheckbox={handleOnChangecheckbox}
        submitHandler={submitHandler}
      />
    </>
  );
};

export default BookingSlot;
