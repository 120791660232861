import React from "react";

const Training = () => {
  return <>
   <div className="bio">

   </div>
  </>;
};

export default Training;
