import React, { useEffect, useRef, useState } from "react";
// import "./Dashboard.css";
import logo from "../../assets/images/profileIcon.png";
import { useNavigate, useParams } from "react-router-dom";
import "./Coachprofiledetail.css"
// import Earning from "./Earning";
// import Training from "./Training";
// import VideoLesson from "./VideoLesson";
// import ChangePassword from "./ChangePassword";
import { Button, Carousel, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { PhotoUploadAction } from "../../redux/actions/UploadPhoto";
import { AdminGetProfileDetailAction } from "../../redux/actions/AdminGetProfileDetail";
import { emptyPasswordResponse } from "../../redux/actions/ChangePassword";
import { emptyUpdateProfileResponse } from "../../redux/actions/AdminProfileUpdateAction";
import {
  GetCoachByIdAction,
  GetCoachProfileAction,
} from "../../redux/actions/coach";
import BasicDetail from "../../components/BookingComponent/BasicDetail";
import Bookings from "../../components/BookingComponent/Bookings";
import Location from "../../components/CoachProfile/Location";
import { ReviewAction } from "../../redux/actions/ReviewAction";
import { Pagination } from "@mui/material";
// import CoachDocs from "../CoachProfile/CoachDocs";
// import Review from "./Review";

const CoachProfileDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { profileDetail, profileError } = useSelector(
  //     (state) => state.getProfileDetail
  // );
  // const getResponse = profileDetail?.statusCode;

  const { getCoachProfile } = useSelector((state) => state.getAllCoachResponse);
  const { getAllSports } = useSelector((state) => state.getAllSportsResponse);
  const { reviewReducer } = useSelector((state) => state.getReviewReducer);
  const data = getCoachProfile?.data;

  const response = getCoachProfile?.statusCode;
  console.log("get review ", reviewReducer);

  const [list, setList] = useState();
  const [venue, setVenue] = useState()
  const [open, setOpen] = useState(false);
  const [venueSlidder, setVenueSlidder] = useState();
  const [index, setIndex] = useState(0);
  useEffect(() => {
    if (response == 200) {
      setList(data);
    }
  }, [getCoachProfile]);

  // const coachId = localStorage.coachId;

  useEffect(() => {
    let obj = {
      coachId: id,
      userId: id,
      page: 1,
      pageSize: 10,
      reviewsFilter: 1,
    };
    // localStorage.setItem("coachId", data.coachId);
    dispatch(ReviewAction(obj));
    dispatch(GetCoachProfileAction(obj));
  }, []);

  // const basicDetailHandler = () => {
  //     setShow({ ...defaultShow, basicDetail: true });
  //     dispatch(emptyUpdateProfileResponse());
  // };

  console.log("localStorage.coachId=>>>>>>", localStorage.coachId);

  const sidebarHandler = () => {
    setOpen(!open);
  };
  const bookingHandler = (id) => {
    // let obj = {
    //   coachId: id,
    // };

    // dispatch(GetCoachProfileAction(obj));
    navigate("/booking");
  };

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const totalCount = reviewReducer?.data?.length;

  useEffect(() => {
    const venue = JSON.parse(localStorage.getItem('venue'));
    if (venue?.selectVenue?.length > 0) {
      setVenue(venue?.selectVenue);
    }
    if (venue?.radiusVenue?.length > 0) {
      setVenue(venue?.radiusVenue);
    }
  }, []);
  console.log("venuessss", venue)

  const isloader = () => {
    if (getCoachProfile?.statusCode != 200) {
      return <Spinner animation="border" variant="warning" />;
    } else if (getCoachProfile?.statusCode == 200) {
      return null;
    } else if (getCoachProfile?.statusCode > 200) {
      return "Something went wrong !";
    }
  };


  return (
    <>
      <div id="dashboard">
        <div className="dashboard_desc">
          <h1>Coach Profile Details</h1>
          {/* <p>{getCoachById?.address}</p> */}
        </div>
      </div>
      {/* <div className="dashboard_conatiner"> */}
      <div className="container-fluid">
        <div className="row flex-nowrap">
          <div class="col-auto px-0">
            <div
              id="sidebar"
              className="collapse collapse-horizontal show border-end"
              style={{ height: "100%" }}
            >
              <div
                id="sidebar-nav"
                className="list-group border-0 rounded-0 text-sm-start min-vh-100"
              >

                {/* <img src={logo} alt="icon" /> */}
                <img
                  style={{
                    height: "150px",
                    width: "150px",
                    borderRadius: "100px",
                  }}
                  src={data?.profileImage || logo}
                />
                <div className="d-flex flex-column
                list-group-item border-end-0 d-inline-block text-truncate side_detail"
                  data-bs-parent="#sidebar">
                  <a style={{ marginTop: "20px" }} href="#bio"> <span >Bio</span></a>
                  <a style={{ marginTop: "20px" }} href="#location_id"><span >Location</span></a>
                  <a style={{ marginTop: "20px" }} href="#venue_id"><span >venue</span></a>
                  <a style={{ marginTop: "20px" }} href="#review_id"><span >Review</span></a>
                </div>


              </div>
            </div>
          </div>
          {/* {getCoachProfile?.success === 200 ? ( */}
          <main className="col ps-md-2 pt-2 right_sidebar">
            <a
              data-bs-target="#sidebar"
              data-bs-toggle="collapse"
              class="p-1 text-decoration-none"
              style={{ cursor: "pointer" }}
              onClick={sidebarHandler}
            >
              {open ? (
                <i className="fa fa-chevron-right bi-lg py-2 p-1"></i>
              ) : (
                <i className="fa fa-chevron-left bi-lg py-2 p-1"></i>
              )}
            </a>
            <div className="page-header pt-3" style={{ marginLeft: "80px", marginRight: "80px" }}>
              <div
                className="card col-12 detailCard"
                style={{
                  background: "linear-gradient(354.9deg,rgba(113, 113, 113, 0.1) 4.41%, rgba(255, 255, 255, 0.1) 91.68%    )",
                }}
              >
                <div
                  className="card-body col-12"
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                  id="bio"
                >
                  <div className="col-md-4">
                    <h4 >Coach Name</h4>
                    <h3>{data?.name}</h3>
                  </div>
                  <div className="col-md-4">
                    <h4>Price</h4>
                    <h3>{data?.price}</h3>
                  </div>

                  <div className="col-md-4 d-flex align-items-center" >
                    <button
                      className="book_lesn_btn"
                      onClick={() => bookingHandler(data.coachId)}
                    >
                      Book Lesson
                    </button>
                  </div>
                </div>
              </div>
              <div className="about-me">
                <h2 >About Me</h2>
                <h5>{data?.bio}</h5>
              </div>

              <div className="bottom-border " id="location_id">
              </div>

              < Location />
              <div className="bottom-border " id="venue_id" >
              </div>

              <div
                className=" col-sm- 12 venue-card" >
                <h2>Venue</h2>
                <Carousel
                  activeIndex={index}
                  onSelect={handleSelect}
                // style={{ height: "100%", width: "100%" }}
                >

                  {venue?.map((venue) => {
                    return (
                      <Carousel.Item
                        style={{ height: "300px", width: "100%" }}
                      >
                        {/* <div style={{ height: "500px", width: "100%" }}> */}
                        <img
                          style={{
                            position: "absolute",
                            display: "block",
                            height: "100%",
                            width: "100%",
                          }}
                          src={venue?.image}
                        />
                        {/* </div> */}
                        <Carousel.Caption>
                          <h4>{venue?.name}</h4>
                        </Carousel.Caption>
                      </Carousel.Item>
                    )
                  })}



                  {venue?.radiusVenue?.map((venue) => {
                    return (
                      <Carousel.Item
                        key={venue.id}
                        style={{ height: "300px", width: "100%" }}
                      >
                        {/* <div style={{ height: "500px", width: "100%" }}> */}
                        <img
                          style={{
                            // position: "absolute",
                            // display: "block",
                            height: "100%",
                            width: "100%",
                          }}
                          src={venue?.image}
                        />
                        {/* </div> */}
                        <Carousel.Caption>
                          <h3>{venue.name}</h3>
                        </Carousel.Caption>
                      </Carousel.Item>
                    );
                  })}
                </Carousel>
              </div>

              <div className="bottom-border " id="review_id">
              </div>

              <div className="review-container1" >
                {/* <div className="row justify-content-start"> */}
                <h2>
                  Reviews
                </h2>
                <div
                  className="card col-12 row"
                  style={{
                    background: "#313131",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >

                  {reviewReducer?.data?.map((review) => {
                    return (
                      <div
                        className="card col-4"
                        style={{
                          background: "black",
                          width: "32%",
                          // height: "",
                          display: "flex",
                          alignItems: "center",
                          marginTop: "20px",
                          borderRadius: "10%",
                        }}
                      >
                        <img
                          className="card-img-top"
                          src={review?.studentImage}
                          alt="Card image cap"
                          style={{
                            height: "100px",
                            width: "100px",
                            borderRadius: "50%",
                            borderInlineStyle: " solid",
                            borderInlineColor: "rgba(227, 130, 38, 1)",
                            marginTop: "20px",
                          }}
                        />
                        <div
                          className="card-body"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <h3 className="card-title h3">{review?.studentName}</h3>
                          <h4>{review?.review}</h4>
                          <h3 className="d-flex  h3">
                            {Array.from(Array(review?.rating).keys()).map(
                              () => (
                                <div className="pr-2">
                                  <i class="fa fa-star" aria-hidden="true"></i>
                                </div>
                              )
                            )}
                          </h3>
                        </div>
                      </div>
                    );
                  })}
                  {/* <br /> */}
                  {/* <Pagination
                    count={totalCount}
                    variant="outlined"
                    shape="rounded"
                  /> */}
                </div>
              </div>
              {/* </div> */}
            </div>
          </main>
           {/* ) : (isloader())} */}

        </div>
      </div>

      {/* < Bookings
                id={id} /> */}
    </>
  );
};

export default CoachProfileDetail;
