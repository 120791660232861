import React from "react";
import "./About.css";

import logo1 from "../../assets/images/logo1.png";
import logo3 from "../../assets/images/logo3.png";
import logo4 from "../../assets/images/logo4.png";
import logo5 from "../../assets/images/logo5.png";
import logo6 from "../../assets/images/logo6.png";
import icon1 from "../../assets/images/AboutIcon1.png";
import icon2 from "../../assets/images/AboutIcon2.png";
import icon3 from "../../assets/images/AboutIcon3.png";
import team from "../../assets/images/team.png";
import person from "../../assets/images/person.jpg"
import Services from "../Services";
import Games from "../Games";

const About = () => {
  return (
    <>

      <div className="about ">
        <div className="about_content">
          <h1 className="about_header ">
            Who We are, Explore Our <br /> Mission & Vision
          </h1>
        </div>
      </div>

      <div className="about_cards">
        <div className="container ">
          <div className="row justify-content-center">
            <div className="col-md-10 about_box ">
              <h1>About Us</h1>
              <p className="about_box">
                We know the parental struggle to find the right coach for your
                kid. We understand the student desire to succeed in a sport. And
                we recognize the importance of mentorship for students.
              </p>
              <p className="about_box">We’re here to provide all of the above and more!</p>
              <p className="about_box">
                Launched in March, 2023, Top Tier Lessons aims to provide a
                platform to match college student-athletes with parents looking
                for sports lessons for their kids.
              </p>
              <p className="about_box">
                At Top Tier Lessons, our coaches are ready to share their
                expertise and knowledge to help inspire the future generation of
                athletes. Our student athletes are more than just coaches. They
                are mentors and leaders in their communities. And now they are
                ready to make a difference for the next generation.
              </p >
            </div>
            {/* </div> */}
            <div style={{ marginTop: "50px" }} className="col-md-10">
              <h2 className="about_box_h2">Accessible private coaching near you</h2>
              <p className="about_para">
                Discover the best varsity athletes in your local neighborhood
                for only the most top tier lessons for your kids. As we look
                towards building our community, we offer:
              </p>
              <div className="about_box_list">
                <div>
                  <p> <i class="fa fa-check" aria-hidden="true"></i> &nbsp; Verified reviews by other parents</p>
                  <p> <i class="fa fa-check" aria-hidden="true"></i> &nbsp; Athlete identity checks to ensure safety</p>
                </div>
                <div>
                  <p> <i class="fa fa-check" aria-hidden="true"></i> &nbsp; Secure payments through our site</p>
                  <p><i class="fa fa-check" aria-hidden="true"></i> &nbsp;  Reminders leading up to lessons</p>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="col-md-12 about_box d-flex justify-content-between ">
              <div className=" row col-md-4 aboutcards ">
                <div className="col-md-12 d-flex justify-content-center">
                  <img className="side_icon" src={icon1} alt="icon" />
                </div>
                <div className="col-md-12 ">
                  <p className="side_text ">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s,
                  </p>
                </div>
              </div>
              <div className=" row col-md-4 aboutcards">
                <div className="col-md-12 d-flex justify-content-center">
                  <img className="side_icon" src={icon2} alt="icon" />
                </div>
                <div className="col-md-12">
                  <p className="side_text">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s,
                  </p>
                </div>
              </div>
              <div className="row col-md-4 aboutcards ">
                <div className="col-md-12 d-flex justify-content-center">
                  <img className="side_icon" src={icon3} alt="icon" />
                </div>
                <div className="col-md-12">
                  <p className="side_text">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s,
                  </p>
                </div>
              </div>
            </div> */}

        </div>
      </div>

      <div className="about_cards">
        <div className="container ">
          <div className="row justify-content-center">
            <div className="col-md-10 about_box ">
              <h2 className="about_box_h2">Every Sport </h2>
              <p className="about_para">
                You name it, we have it. Whether you need swimming lessons for
                your 5 year old or tennis lessons for your 16 year old, we have a
                coach that’s the perfect match for you, your child, and their
                future athletic goals.
              </p>
              <Games />
            </div>
          </div>
        </div>
      </div>

      {/* Teams  */}
      <div className="team">
        <p className="team_header">Get DEEP WITH </p>
        <p className="team_desc">OUR TEAM</p>
        <div className="container">
          <div className="row">
            <div className="col-md-12 mx-auto ">
              {/* ceo  */}
              <div>
                <div className="card col-md-12" style={{ display: "flex", flexDirection: "row", alignItems: "baseline", background: "black" }}>
                  <img className="card-img-left col-md-6" src={person} alt="Card image cap" style={{ height: "300px", width: "150" }} />
                  <div className="card-body">
                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                  </div>
                </div>
              </div>
              {/* other employee */}

              <div className="container col-md-12 " style={{ display: "flex", flexDirection: "row", alignItems: "baseline", justifyContent: "space-between" }}>
                <div className="card col-md-3 align-items-center" >
                  <img className="card-img-left col-md-6" src={team} alt="Card image cap" style={{ height: "100px", width: "100" }} />
                  <div className="card-body">
                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                  </div>
                </div>
                <div className="card col-md-3 align-items-center" >
                  <img className="card-img-left col-md-6" src={team} alt="Card image cap" style={{ height: "100px", width: "100" }} />
                  <div className="card-body">
                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                  </div>
                </div>

                <div className="card col-md-3 align-items-center" >
                  <img className="card-img-left col-md-6" src={team} alt="Card image cap" style={{ height: "100px", width: "100" }} />
                  <div className="card-body">
                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                  </div>
                </div>

                <div className="card col-md-3 align-items-center" >
                  <img className="card-img-left col-md-6" src={team} alt="Card image cap" style={{ height: "100px", width: "100" }} />
                  <div className="card-body">
                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                  </div>
                </div>

              </div>



              <div
                id="myCarousel"
                className="carousel slide"
                data-ride="carousel"
                data-interval="0"
              >
                {/* <!-- Wrapper for carousel items --> */}
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="d-flex justify-content-center">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                        <div className="thumb-wrapper">
                          <div className="img-box">
                            <img src={team} className="img-fluid" alt="" />
                          </div>
                          <div className="thumb-content">
                            <h4>Jeane Jhin</h4>
                            <p className="team_card_desc">
                              Social media specialist New York, USA.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                        <div className="thumb-wrapper">
                          <div className="img-box">
                            <img src={team} className="img-fluid" alt="" />
                          </div>
                          <div className="thumb-content">
                            <h4>Jeane Jhin</h4>
                            <p className="team_card_desc">
                              Social media specialist New York, USA.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                        <div className="thumb-wrapper">
                          <div className="img-box">
                            <img src={team} className="img-fluid" alt="" />
                          </div>
                          <div className="thumb-content">
                            <h4>Jeane Jhin</h4>
                            <p className="team_card_desc">
                              Social media specialist New York, USA.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="d-flex ">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                        <div className="thumb-wrapper">
                          <div className="img-box">
                            <img src={team} className="img-fluid" alt="" />
                          </div>
                          <div className="thumb-content">
                            <h4>Jeane Jhin</h4>
                            <p className="team_card_desc">
                              Social media specialist New York, USA.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                        <div className="thumb-wrapper">
                          <div className="img-box">
                            <img src={team} className="img-fluid" alt="" />
                          </div>
                          <div className="thumb-content">
                            <h4>Jeane Jhin</h4>
                            <p className="team_card_desc">
                              Social media specialist New York, USA.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                        <div className="thumb-wrapper">
                          <div className="img-box">
                            <img src={team} className="img-fluid" alt="" />
                          </div>
                          <div className="thumb-content">
                            <h4>Jeane Jhin</h4>
                            <p className="team_card_desc">
                              Social media specialist New York, USA.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- Carousel controls --> */}
                <a
                  className="carousel-control-prev"
                  href="#myCarousel"
                  data-slide="prev"
                >
                  <i className="fa fa-angle-left"></i>
                </a>
                <a
                  className="carousel-control-next"
                  href="#myCarousel"
                  data-slide="next"
                >
                  <i className="fa fa-angle-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container-fluid logos pb-5">
        <h2>Over 32k+ software businesses growing with Ar Shakir.</h2>
        <div
          className="row pb-2 pt-2"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="col-md-6 col-lg-2 text-center mt-4">
            <img src={logo1} alt="log" />
          </div>
          <div className="col-md-6 col-lg-2 text-center mt-4">
            <img src={logo3} alt="log" />
          </div>
          <div className="col-md-6 col-lg-2 text-center mt-4">
            <img src={logo4} alt="log" />
          </div>
          <div className="col-md-6 col-lg-2 text-center mt-4">
            <img src={logo5} alt="log" />
          </div>
          <div className="col-md-6 col-lg-2 text-center mt-4">
            <img src={logo6} alt="log" />
          </div>
        </div>
      </div> */}

      <div className="container-fluid dark_bg">
        <div className="container newsletter_form">
          <div className="row main_box ">
            <div className="col-md-3 form_box">
              <input
                type="text"
                className="form-control newsletter_inp"
                placeholder="Name"
              />
            </div>
            <div className="col-md-3 form_box">
              <input
                type="text"
                className="form-control newsletter_inp"
                placeholder="Email"
              />
            </div>
            <div className="col-md-3 form_box">
              <button className="newsletter_btn">
                Subscribe Our Newsletter
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <Services /> */}
      <div className="container-fluid line"></div>
    </>
  );
};

export default About;
