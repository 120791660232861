import React, { useState } from "react";
import team from "../../assets/images/team.png";

const UpcomingBooking = ({
  list,
  viewDetailHandler,
  approveBookingHandler,
  deleteBookingHandler,
  usertype,
  loading,
}) => {
  return (
    <div className="col-md-sm-xs-12">
      {list?.length > 0 ? (
        list?.map((user) => {
          let dateupdate = new Date(user.bookingDate).toLocaleDateString();
          return (
            <div>
              <div className="name_section">
                <div
                  className="pic_side w-25 my-auto "
                  style={{ textAlign: "center" }}
                  // onClick={() => profileHandler(user)}
                >
                  <img
                    src={user?.profileImage ? user?.profileImage : team}
                    alt="img"
                    height="50px"
                    width="50px"
                    // style={{ borderRadius: "50%", paddingLeft: "20px" }}
                  />
                </div>
                <div
                  style={{
                    borderLeft: "1px solid #575757",
                    height: "70px",
                  }}
                ></div>
                <div className="coach_price col-md-sm-xs-2">
                  <div className="coach_price_label">
                    <h3>Name</h3>
                  </div>
                  <div className="coach_fullname">
                    {usertype === 2 ? (
                      <h4 className="text-white">{user?.studentName}</h4>
                    ) : (
                      <h4 className="text-white">{user?.coachName}</h4>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    borderLeft: "1px solid #575757",
                    height: "70px",
                  }}
                ></div>
                <div className="coach_price col-md-sm-xs-3">
                  <div className="coach_price_label">
                    <h3>Booking Date</h3>
                  </div>
                  <div className="coach_full_price">
                    <h4 className="text-white">{dateupdate}</h4>
                  </div>
                </div>
                <div
                  style={{
                    borderLeft: "1px solid #575757",
                    height: "70px",
                  }}
                ></div>
                <div className="coach_price col-md-sm-xs-3">
                  <div className="coach_price_label">
                    <h3> View Detail </h3>
                  </div>
                  <div className="coach_full_price">
                    <i
                      className="fa fa-eye text-white fs-5 cursor_pointer  "
                      aria-hidden="true"
                      onClick={() => viewDetailHandler(user.bookingId)}
                    ></i>
                  </div>
                  {/* View Detail */}
                </div>
                <div
                  style={{
                    borderLeft: "1px solid #575757",
                    height: "70px",
                  }}
                ></div>
                {usertype === 2 ? (
                  <div className="coach_price col-md-sm-xs-3">
                    <div className="coach_price_label">
                      <h3> Approve </h3>
                    </div>
                    <div className="coach_full_price">
                      <i
                        className="fa fa-check text-white fs-5 cursor_pointer"
                        aria-hidden="true"
                        onClick={() => approveBookingHandler(user.bookingId)}
                      ></i>
                    </div>
                  </div>
                ) : null}

                <div
                  style={{
                    borderLeft: "1px solid #575757",
                    height: "70px",
                  }}
                ></div>
                <div className="coach_price col-md-sm-xs-3">
                  <div className="coach_price_label">
                    <h3> Cancel </h3>
                  </div>
                  <div className="coach_full_price">
                    <i
                      className="fa fa-times text-white fs-5 cursor_pointer"
                      aria-hidden="true"
                      onClick={() => deleteBookingHandler(user.bookingId)}
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : !loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <h4 className="text-white">Data Not Found</h4>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center">
          <div className="spinner-border text-light" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
};
export default UpcomingBooking;
